import { SaveModal, IscModalAlert, AlertSetting } from "@design/styleguide";
import SideBarMessage from "./components/sideBarMessage/SideBarMessage.vue";
import SideBarDesign from "./components/sideBarDesign/SideBarDesign.vue";
import SideBarQuestion from "./components/sideBarQuestion/SideBarQuestion.vue";
export default {
  name: "SidebarModule",
  components: {
    SaveModal,
    SideBarDesign,
    SideBarMessage,
    SideBarQuestion,
    IscModalAlert
  },
  mixins: [ AlertSetting ],
  props: {
    element: {
      type: Object,
      default: () => {
        return {};
      },
    },
    questionSelectIndex: {
      type: Number,
      default: -1,
    },
    items: {
      type: Array,
      default: () => {
        return [];
      },
    },
		clientId: {
			type: Number,
			default: 0
		}
  },
  data() {
    return {
      tab: null,
      tool: 0,
      titles: [{ tab: "Questions" }, { tab: "Message" }, { tab: "Design" }],
    };
  },
  computed: {
    message() {
      return this.$store.getters["addons/create/message"];
    },
    isHiddenButton(){
      return this.$store.getters["addons/saved/hiddenSaveButton"];
    }
  },
  methods: {
    redirectUrl(){
      const url = `/publish?type=addon&uuid=${this.element.addonUuid}`
      this.$router.push(url)
    },
    createItemQuestion(type) {
      this.$emit("type-question", type);
    },
    linkText(value) {
      this.$emit("send-link", value);
    },
    idFolder(idFolder, setIdAddon) {
      this.$emit("id-folder", idFolder, setIdAddon);
    },
    sentText(val) {
      this.$emit("text", val);
    },
    sendConfirmation(val) {
      this.$emit("show-message", val);
    },
    editAddon(val) {
      this.$emit("edit-addon", val);
    },
    //** ALERTS MODAL */
    setSuccessMessage(msg) {
      //* this function comes from AlertMixin
      this.successModal(msg);
    },
    setErrorMessage(msg){
      //* this function comes from AlertMixin
      this.errorModal(msg);
    }
  },
};
