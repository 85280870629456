<template>
  <form enctype="multipart/form-data">
    <div class="dropzone">
      <v-sheet
        v-if="!uploading"
        class="upload-sheet-ds"
      >
        <div class="mx-auto drop_bx">
          <div class="drop_bx_img_st">
            <v-img src="https://i.ibb.co/BTVTkLp/Vector.png" max-height="34" width="49" contain>
          </v-img>
          </div>
          
          <div class="message-dropzone">
            <h5 class="label-dropzone dark-blue-ds mr-1">Drag and Drop</h5>
            <span class="label-dropzone dark-blue-ds mr-1">or</span>
            <h5
              class="label-dropzone skyblue-text"
              style="text-decoration: underline"
            >
              Browse your file
            </h5>
          </div>
        </div>
      </v-sheet>
      <input ref="file" type="file" class="input-file" @change="sendFile" />
      <div>
        <p v-if="!uploading" class="call-to-action"></p>
        <div v-else>
          <div v-if="item.progress < 100">
            <v-progress-circular
              :rotate="-90"
              :size="100"
              :width="15"
              :value="item.progress"
              color="primary"
            >
              {{ item.progress }} %
            </v-progress-circular>
          </div>
          <div v-if="item.progress === 100" style="padding-top: 36px">
            <div class="circular">
              <v-progress-circular
                indeterminate
                color="#C5D0E4"
                size="25"
                width="3"
              >
              </v-progress-circular>
            </div>
            <p v-if="!item.error" class="percentage">please wait ...</p>
            <p v-else class="percentage">Error: Failed to upload image.</p>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { Swal } from "@design/styleguide";
export default {
  name: "CpDropzone",
  props: {
    item: {
      type: [Object, Array],
      default: () => {
        return {
          progress: 0,
          error: false,
        };
      },
    },
    uploading: {
      type: Boolean,
      default: false,
    },
    resetFile: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      upload_url:
        process.env.VUE_APP_UPLOAD_IMAGES ||
        "https://v9hd7n6ond.execute-api.us-east-1.amazonaws.com/dev/upload",
      file: null,
      message: "",
      error: false,
    };
  },
  watch:{
    resetFile(val){
      if(val) {
        this.$refs.file.value = null;
        this.$emit('reset-value');
      }
    },
  },
  methods: {
    async sendFile() {
      const file = this.$refs.file.files[0];
      if (!file) return;
      this.imageDimentions(file);
      //this.$emit("file", file);
    },
    swalAlert(){
      Swal.fire({
        title: "Oops...",
        text: "Upload an image larger than 160 * 160 pixels.",
        icon: "error",
        showConfirmButton: false,
        timer: 2000,
      });
    },
    async imageDimentions(file){    
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = evt => {
        let img = new Image();
        img.onload = () =>  (img.width >= 160 && img.height >= 160) ? this.$emit("file", file) : this.swalAlert();
        img.src = evt.target.result;
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.dropzone {
  margin-top: 5px;
  height: 103px;
  max-width: 402px;
  padding: 10px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 0.5px dashed rgba(150, 160, 184 , .5);
  background-color: #f3f4f7;
  border-radius: 6px;
  margin: 0 auto;
  .message-dropzone{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    font-family: Oxygen;
    h5{
      font-size: 12px;
      font-weight: 700 !important;
    }
    span{
      font-size: 12px;
      font-weight: 300 !important;
      display: inline-block;
      padding: 0 2px;
    }
  }
  // outline-offset: -10px;
  .upload-sheet-ds{
    background-color: #f3f4f7 !important;
    width: 100%; 
    height: 101px;
    .drop_bx{
      background-color: inherit !important;
      height: inherit;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
  }
  .input-file {
    opacity: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    cursor: pointer;
  }
  .call-to-action {
    font-size: 1.2rem;
    color: #c5d0e4;
  }
  .percentage {
    margin-top: 20px;
    font-weight: 700;
    font-size: 12px;
    opacity: 0.9;
    text-align: center;
  }
  .circular {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 4px;
  }
}
</style>
