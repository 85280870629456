<template>
  <element>
    <v-combobox
      class="button-box"
      :items="items"
      :height="height"
      flat
      solo
      placeholder="Select"
      dense
      item-text="name"
      @change="selectedValue"
      :value="value"
      no-filter
      hide-details=""
      :menu-props="{ contentClass: 'menuStyleDes' }"
      :prepend-inner-icon="innerIcon"
    >
      <template slot="item" slot-scope="data">
        <div class="item_class d-flex align-center">
            <div class="col-1 pa-0 mr-3">
              <v-icon
                v-if="showIcon"
                
                :style="{ 'font-size': sizeIcon }"
                :class="{ [classIcon]: even }"
              >
                {{ data.item.icon }}
              </v-icon>
            </div>
            <span class="common-text col px-0 pt-1 pb-0" :class="{ [fontWeight]: even }">
              {{ data.item.name }}
            </span>
        </div>
      </template>
    </v-combobox>
  </element>
</template>
<script>
export default {
  name: "ComboboxIcon",
  props: {
    items: {
      type: Array,
      default: () => {
        return [];
      },
    },
    height: {
      type: Number,
      default: 40,
    },
    value: {
      type: [String, Object],
    },
    showIcon: {
      type: Boolean,
      default: true,
    },
    fontWeight: {
      type: String,
      default: "font-weight-regular",
    },
    even: {
      type: Boolean,
      default: true,
    },
    classIcon: {
      type: String,
      default: "class-icon-default",
    },
    innerIcon: {
      type: String,
      default: "",
    },
    sizeIcon: {
      type: String,
      default: "20px",
    },
  },
  data() {
    return {};
  },
  methods: {
    selectedValue(val) {
      this.$emit("selected-item", val);
    },
  },
};
</script>

<style lang="scss" scoped>
.item_class{
  width: 100%;
}
.button-box {
  font-family: Oxygen;
  font-size: 12px;
  line-height: 16px;
  ::v-deep .v-input__slot {
    border: 1px solid #c5d0e4;
    box-shadow: inset 0px 2px 5px rgba(197, 208, 228, 0.62);
    cursor: pointer !important;
    .v-input__prepend-inner {
      padding-top: 5px;
      .v-icon {
        font-size: 16px;
        color: #5d6b88 !important;
      }
    }
  }
  ::v-deep .v-select__slot {
    input {
      caret-color: transparent;
      cursor: pointer;
      color: #5d6b88 !important;
    }
  }
}

.class-icon-default {
  color: #96a0b8;
  margin-right: 19px;
}
.class-icon-reactions {
  color: #5d6b88;
  margin-right: 6px;
}
</style>
