<template>
  <div class="side__parent">
    <v-navigation-drawer permanent width="202" class="sidebar__content">
      <div class="content__text">
        <v-list-item class="content__title">
          <h4 class="title-menu text-white">Template Gallery</h4>
        </v-list-item>
        <v-list-item class="content__description mt-3">
          <h6 class="description-menu text-white">
            Use templates to get to know ISC, or just to inspire. We have ideas
            for everything you may need!
          </h6>
        </v-list-item>
      </div>
      <v-list flat nav id="no-bg" class="menu">
        <div v-for="item in menus" :key="item.name">
          <v-list-item exact @click="sendData(item.path)" class="listItems">
            <v-list-item-icon class="align-center mr-0 mt-0 mb-0 ">
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content class="pt-0 pb-0">
              <v-list-item-title
                v-text="item.name"
                class="text-white"
                style="font-size: 12px"
              ></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  name: "SidebarContent",
  data: () => ({
    //drawer: false,
    menus: [
      {
        icon: "fas fa-award",
        name: "Contest",
        path: "#contest",
      },
      {
        icon: "fas fa-list",
        name: "Survey",
        path: "#survey",
      },
      {
        icon: "fas fa-tag",
        name: "Promo",
        path: "#promo",
      },
      {
        icon: "fas fa-user",
        name: "Audience",
        path: "#audience",
      },
    ],
  }),
  methods: {
    sendData(path) {
      this.$emit("id-path", path);
    },
  },
};
</script>
<style scoped lang="scss" src="./SidebarContent.scss"></style>
