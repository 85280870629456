<template>
  <v-col cols="12" class="container-question-range px-0 pt-3 pb-3">
    <div
      v-for="(index) in numberRange"
      :key="index"
      class="box-number"
      >
      <span v-if="rangeType.name == 'numbers'"> {{ index }} </span>
      <v-icon color="#5D6B88" size="16" v-if="rangeType.name !== 'numbers'">{{ rangeType.icon }}</v-icon>
    </div>
  </v-col>
</template>

<script>
export default {
  name: "InputCheckboxQuestion",
  props:{
     numberRange: {
      type: Number,
      default: 1,
    },
     rangeType: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {

      questionNumbers: [
        { value: 0 },
        { value: 1 },
        { value: 2 },
        { value: 3 },
        { value: 4 },
        { value: 5 },
        { value: 6 },
        { value: 7 },
        { value: 8 },
        { value: 9 },
        { value: 10 },
      ],
    };
  }
};
</script>
<style scoped lang="scss">
.container-question-range {
  display: flex;  
  justify-content: space-evenly;
}
.box-number {
  height: 35px;
  width: 32px;
  background-color: #f3f4f7;
  padding: 7px 11px;
  margin: 0;
  border-radius: 3px;
  display: inherit;
  justify-content: center;
  align-items: center;
  span{
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #5D6B88;
  }
}
</style>
