<template>
  <element>
    <v-combobox
      :style="{ 'max-width': maxWidth }"
      class="combobox-box"
      :items="items"
      :height="height"
      flat
      solo
      :placeholder="placeholder"
      dense
      item-text="title"
      @change="selectedValue"
      :value="value"
      no-filter
      hide-details=""
      chips
    >
    </v-combobox>
  </element>
</template>
<script>
export default {
  name: "SmallCombobox",
  props: {
    items: {
      type: Array,
      default: () => {
        return [];
      },
    },
    height: {
      type: Number,
      default: 40,
    },
    value: {
      type: [String, Object, Number],
    },
    showIcon: {
      type: Boolean,
      default: true,
    },
    fontWeight: {
      type: String,
      default: "font-weight-regular",
    },
    placeholder: {
      type: String,
      default: "",
    },
    maxWidth: {
      type: String,
    },
  },
  data() {
    return {};
  },
  methods: {
    selectedValue(val) {
      this.$emit("selected-item", val);
    },
  },
};
</script>

<style lang="scss" scoped>
.combobox-box {
  font-family: Oxygen;
  font-size: 12px;
  line-height: 16px;
  ::v-deep .v-input__slot {
    border: 1px solid #c5d0e4;
    box-shadow: inset 0px 2px 5px rgba(197, 208, 228, 0.62);
    cursor: pointer !important;
    padding-left: 0px !important;
  }
  ::v-deep .v-select__slot {
    input {
      caret-color: transparent;
      cursor: pointer;
    }
    .v-input__append-inner {
      margin-right: -7px !important;
    }
    .theme--light.v-chip:not(.v-chip--active) {
      background: none;
      margin-left: 1px;
      &:hover::before,
      &:focus::before {
        opacity: 0;
      }
    }
  }
}
</style>
