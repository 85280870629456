<template>
  <v-sheet height="56" class="header-addon">
    <v-row style="height: 100%; margin: 0px" justify="space-between">
      <v-col cols="4" class="pl-0 header-name">
        <input
          type="text"
          id="name"
          name="name"
          placeholder="My addon name"
          autocomplete="off"
          :class="!changeInputBorder ? 'title-addon' : 'title-border'"
          v-model="element.name"
          :disabled="disabled"
        />
        <v-btn @click="changeIcon" icon>
          <v-icon
            small
            :color="iconTitleColor"
            :class="!changeInputBorder ? 'icon-edit' : 'icon-check'"
          >
            {{ icon }}
          </v-icon>
        </v-btn>
      </v-col>
      <v-col cols="4" class="header-undo pl-0 pr-0" v-show="editAddon">
        <v-btn icon class="back" @click="undo" :disabled="canUndo" >
          <v-icon class="back-icon" color="#5D6B88">$back-step</v-icon>
        </v-btn>

        <v-btn icon class="next" @click="redo" :disabled="canRedo" >
          <v-icon class="next-icon" color="#5D6B88">$next-step</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="4" class="header-buttons">
        <cp-btn-white
          name="Edit"
          class="mr-3 buttonHover"
          @click="backToEdit()"
          v-show="isHiddenButton"
        />

        <cp-btn-white
          name="Preview"
          class="buttonHover buttonHover-preview"
          @click="goPreview()"
          v-show="editAddon"
        />
        <cp-btn-white
          name="Edit"
          class="mr-3 buttonHover"
          @click="goPreview()"
          v-show="!editAddon"
        />

        
        <v-dialog v-if="!element.addonId" v-model="dialog" width="500">
          <template v-slot:activator="{ on, attrs }">
            <cp-btn-white
              :disabled="isHiddenButton || !editAddon"
              name="New"
              v-bind="attrs"
              v-on="on"
              class="buttonHover buttonHover-new"
              :show-icon="true"
              icon-name="mdi-plus-thick"
              :size-icon="16"
            />
          </template>
          <v-sheet height="221">
            <div style="padding: 25px">
              <v-btn
                icon
                :ripple="false"
                class="icon-close-ds"
                style="width: 100%; place-content: flex-end"
              >
                <v-icon @click="dialog = false" color="#696B76"
                  >mdi-close</v-icon
                >
              </v-btn>
              <div style="margin: 10px 45px 30px 45px">
                <h3 class="title-modal text-center">
                  Do you want to
                  <span class="fw-700">save this addon</span> before you leave?
                </h3>
              </div>
              <v-row justify="center">
                <v-col cols="4" style="text-align: end">
                  <cp-btn-white @click="closeDialog" name="Don’t save" />
                </v-col>
                <v-col cols="4">
                  <save-modal
                    v-if="!element.addonId"
                    type="addon"
                    @folder-id="idFolder"
                    @close-outside-dialog="endDialog"
                    :hide-init-modal="true"
                    :nameProject="element.name"
                    :id-project="element.addonId"
                  />
                  <cp-btn-common v-else @click="saveImage" name="Save" />
                </v-col>
              </v-row>
            </div>
          </v-sheet>
        </v-dialog>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
import CpBtnWhite from "../../buttons/CpBtnWhite.vue";
import CpBtnCommon from "../../buttons/CpBtnCommon.vue";
import { SaveModal, messagePopUp } from "@design/styleguide";

export default {
  name: "HeaderModule",
  components: {
    CpBtnWhite,
    SaveModal,
    CpBtnCommon,
  },
  props: {
    element: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      editAddon: true,
      dialog: false,
      disabled: false,
      icon: "mdi-lead-pencil",
      addonId: null,
      changeInputBorder: false,
      triggerService: false,
      iconTitleColor: '#96A0B8'
    };
  },
  computed: {
    canRedo() {
      return this.$store.getters["global/redoundoAddon/canRedo"];
    },
    canUndo() {
      return this.$store.getters["global/redoundoAddon/canUndo"];
    },
    isHiddenButton(){
      return this.$store.getters["addons/saved/hiddenSaveButton"];
    }
  },
  watch: {
    editAddon(val) {
      // console.log('EDIT ADDON', val);
      this.$emit("edit", val);
    },
    "element.name"(name) {
      // console.log('addon name', name.length);
      (name.length > 0 && !this.element.addonId) ? this.iconTitleColor = '#5D6B88' : this.iconTitleColor = '#96A0B8';
      this.inputAdjust(name)
      this.$store.dispatch("addons/create/SET_NAME_ADDON", name);
    },
  },
  methods: {
    backToEdit() {
      this.$store.dispatch("addons/saved/IS_HIDDEN_BUTTON", false);
    },
    goPreview() {
      this.$store.dispatch("addons/saved/IS_HIDDEN_BUTTON", false);
      this.editAddon = !this.editAddon;
    },
    saveImage() {
      console.log(" save from header button ");
      this.$emit("update-addon");
      //this.$store.commit("addons/create/SET_SAVED_IMAGE", this.element);
    },
    changeIcon() {
      if (!this.addonId) return;
      if (this.icon === "mdi-lead-pencil") {
        this.icon = "mdi-checkbox-marked-circle";
        this.disabled = false;
        this.changeInputBorder = true;
        this.triggerService = true;
      } else {
        const isName = this.validationName();
        if (!isName) return;
        this.disabled = true;
        this.icon = "mdi-lead-pencil";
        this.changeInputBorder = false;
        if (this.triggerService) {
          const addon = { name: this.element.name };
          console.log(addon, this.element.addonId);
          this.$store.dispatch("addons/saved/UPDATE_NAME", {
            id: this.element.addonId,
            addon,
            responseFunc: this.responseMessageGraphql 
          });
          this.triggerService = false;
        }
      }
    },
    validationName() {
      if (!this.element.name) {
        this.$emit('trigger-error', "It is required Addon's name");
        return false;
      } else return true;
    },
    responseMessageGraphql(msgResp, typeResp ){
      const typeResponse = {
        "error":  () => {
          let modifiedErrMsg = msgResp.replace("GraphQL error:", "")
          this.$emit('trigger-error', modifiedErrMsg);
        },
        "success": () => this.$emit('trigger-success', msgResp)
      }      
      return typeResponse[typeResp]();
    },
    endDialog() {
      this.dialog = false;
    },
    closeDialog() {
      this.dialog = false;
      this.$store.commit("addons/create/RESET_ADDON");
    },
    idFolder(val) {
      this.$emit("id-folder", val);
    },
    inputAdjust(text) {
      //let textInput = text;

      let inputTitle = document.getElementById("name");
      let titleLength = text.length;
      let resizeValue = titleLength *= 8.1;
      if(resizeValue >117 && text.length > 13 && text.length < 46) inputTitle.style.width = `${ Math.round(resizeValue) }.px`;
      if(text.length < 13) inputTitle.style.width = `117.px`;
      
      //console.log('text input', text.length);
    },
    redo() {
      this.$store.dispatch("global/redoundoAddon/REDO");
    },
    undo() {
      this.$store.dispatch("global/redoundoAddon/UNDO");
    },
  },
  mounted() {
    if (this.element.addonId) {
      this.disabled = true;
      this.addonId = this.element.addonId;
    } else {
      this.disabled = false;
    }
    // console.log('mounted header addon', this.element);
    this.inputAdjust(this.element.name);
  },
};
</script>

<style lang="scss" scoped src="./HeaderModule.scss"></style>
