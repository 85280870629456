<template>
  <v-btn
    :class="css"
    :block="block"
    :disabled="disabled"
    :style="[disabled && { backgroundColor: '#96A0B8' }]"
    color="#96A0B8"
    @click="callback($event)"
  >
    <v-progress-circular
      v-if="loading"
      indeterminate
      color="white"
      :size="25"
    ></v-progress-circular>
    <div v-else>{{ name }}</div>
  </v-btn>
</template>

<script>
export default {
  name: "CpBtnGray",
  props: {
    name: String,
    css: {
      type: String,
      default: "gray-button-ds",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    block: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    callback(e) {
      this.$emit("click", e);
    },
  },
};
</script>

<style scoped lang="scss">
.gray-button-ds {
  color: #ffffff !important;
  box-shadow: 0px 2px 4px rgba(44, 56, 82, 0.1);
  border-radius: 3px;
  font-weight: bold;
  padding: 9px 20px !important;
  text-transform: none;
  font-style: normal;
  font-size: 12px;
  line-height: 15px;
  border: 1px solid #c5d0e4 !important;
  box-sizing: border-box;
}
.gray-button-ds > span > div {
  font-size: 12px;
}
</style>
