<template>
  <div
    v-if="itemsKeywords.length === 0"
    id="container"
    class="content__items"
    ref="contentItems"
  >
    <div>
      <h5 id="contest" class="h5-title-addon" style="margin-bottom: 20px">
        Contest
      </h5>
      <template v-if="!isLoadingContest">
        <div v-if="!itemsContest.length" class="is-empty">
          No data available
        </div>
        <cp-card-content
          v-else
          :items="itemsContest"
          :show-arrows="arrowContest"
          value="contest"
          @trigger-charge="chargeData"
        />
      </template>
      <div class="d-flex" v-else>
        <v-skeleton-loader
          type="card"
          v-for="i in 6"
          :key="i"
          width="209"
          class="itemCard"
           style="margin-right: 20px"
        ></v-skeleton-loader>
      </div>
    </div>
    <div>
      <h5
        id="survey"
        class="h5-title-addon"
        style="margin-bottom: 20px; margin-top: 30px"
      >
        Survey
      </h5>
      <template v-if="!isLoadingSurvey">
        <div v-if="!itemsSurvey.length" class="is-empty">
          No data available
        </div>
        <cp-card-content
          v-else
          :items="itemsSurvey"
        :show-arrows="arrowSurvey"
        value="survey"
        @trigger-charge="chargeData"
      />
      </template>
      <div class="d-flex" v-else>
        <v-skeleton-loader
          type="card"
          v-for="i in 6"
          :key="i"
          width="209"
          class="itemCard"
          style="margin-right: 20px"
        ></v-skeleton-loader>
      </div>
    </div>
    <div>
      <h5
        id="promo"
        class="h5-title-addon"
        style="margin-bottom: 20px; margin-top: 30px"
      >
        Promo
      </h5>
      <template v-if="!isLoadingPromo">
        <div v-if="!itemsPromo.length" class="is-empty">
          No data available
        </div>
        <cp-card-content
          v-else
          :items="itemsPromo"
        :show-arrows="arrowPromo"
        value="promo"
        @trigger-charge="chargeData"
      />
      </template>
      <div v-else class="d-flex">
        <v-skeleton-loader
          type="card"
          v-for="i in 6"
          :key="i"
          width="209"
          class="itemCard"
          style="margin-right: 20px"
        ></v-skeleton-loader>
      </div>
    </div>
    <div>
      <h5
        id="audience"
        class="h5-title-addon"
        style="margin-bottom: 20px; margin-top: 30px"
      >
        Audience
      </h5>
      <template v-if="!isLoadingAudience">
        <div v-if="!itemsAudience.length" class="is-empty">
          No data available
        </div>
        <cp-card-content
          v-else
          :items="itemsAudience"
        :show-arrows="arrowAudience"
        value="audience"
        @trigger-charge="chargeData"
      />
      </template> 
      <div v-else class="d-flex">
        <v-skeleton-loader
          type="card"
          v-for="i in 6"
          :key="i"
          width="209"
          class="itemCard"
          style="margin-right: 20px"
        ></v-skeleton-loader>
      </div>
    </div>
  </div>
  <div v-else class="content__items">
    <cp-card-content :items="itemsKeywords" show-arrows="always" />
  </div>
</template>

<script>
import CpCardContent from "../card/CpCardContent.vue";
import { getArrayString, mapActions, mapGetters } from "@design/styleguide";
export default {
  name: "ContentGallery",
  components: { CpCardContent },
  props: {
    pathName: {
      type: String,
      default: "",
    },
    search: {
      type: String,
      default: () => {
        return "";
      },
    },
  },
  data() {
    return {
      drawer: false,
      loading: false,
      selection: 1,
      //search: "",
      showItemContest: false,
      showItemSurvey: true,
      showItemPromo: true,
      showItemAudience: true,
      take: 10,
      pageContest: 1,
      pageSurvey: 1,
      pagePromo: 1,
      pageAudience: 1,
      arrowContest: 'always',
      arrowSurvey: 'always',
      arrowPromo: 'always',
      arrowAudience: 'always',
      amountContest: 0,
      amountAudience: 0,
      amountSurvey: 0,
      amountPromo: 0,
      finalSearch: "",
    };
  },
  created() {
    this.allGallery();
  },
  computed: {
    ...mapGetters({
      itemsContest: "addons/folders/contest",
      itemsSurvey: "addons/folders/survey",
      itemsPromo: "addons/folders/promo",
      itemsAudience: "addons/folders/audience",
      itemsKeywords: "addons/folders/addonsByKeywords",
      isLoadingContest: "addons/folders/isLoadingContest",
      isLoadingSurvey: "addons/folders/isLoadingSurvey",
      isLoadingPromo: "addons/folders/isLoadingPromo",
      isLoadingAudience: "addons/folders/isLoadingAudience",
    }),
  },
  watch: {
    pathName(val) {
      this.goTo(val);
    },
    search(val) {
      this.finalSearch = val;
      if (val === "") {
        this.resetKeywords();
      }
    },
  },
  mounted() {
    let self = this;
    window.addEventListener("keyup", function (event) {
      if (event.keyCode === 13) {
        self.searchAddons();
      }
    });
  },
  destroyed() {
    this.resetContest();
    this.resetSurvey();
    this.resetPromo();
    this.resetAudience();
    this.resetKeywords();
  },
  methods: {
    ...mapActions({
      findAddonsType: "addons/folders/FIND_ADDONS_BY_TYPE",
      resetContest: "addons/folders/RESET_ARRAY_CONTEST",
      resetSurvey: "addons/folders/RESET_ARRAY_SURVEY",
      resetPromo: "addons/folders/RESET_ARRAY_PROMO",
      resetAudience: "addons/folders/RESET_ARRAY_AUDIENCE",
      resetKeywords: "addons/folders/RESET_KEYWORDS",
    }),
    goTo(refName) {
      const contentItemsRef = this.$refs.contentItems;
      const el = this.$el.querySelector(refName);
      if (el) {
        contentItemsRef.scrollTo({
          top: el.offsetTop - 160,
          behavior: "smooth",
        });
      }
    },
    chargeData(item) {
      switch (item) {
        case "contest":
          if (!this.validationContest()) break;
          this.pageContest++;
          this.findAddonsType({
            type: "contest",
            take: this.take,
            page: this.pageContest,
          });
          break;
        case "survey":
          if (!this.validationSurvey()) break;
          this.pageSurvey++;
          this.findAddonsType({
            type: "survey",
            take: this.take,
            page: this.pageSurvey,
          });
          break;
        case "promo":
          if (!this.validationPromo()) break;
          this.pagePromo++;
          this.findAddonsType({
            type: "promo",
            take: this.take,
            page: this.pagePromo,
          });
          break;
        case "audience":
          if (!this.validationAudience()) break;
          this.pageAudience++;
          this.findAddonsType({
            type: "audience",
            take: this.take,
            page: this.pageAudience,
          });
          break;
        default:
          break;
      }
    },
    validationContest() {
      if (this.amountContest < this.itemsContest.length) {
        this.amountContest = this.itemsContest.length;
        return true;
      } else return false;
    },
    validationSurvey() {
      if (this.amountSurvey < this.itemsSurvey.length) {
        this.amountSurvey = this.itemsSurvey.length;
        return true;
      } else return false;
    },
    validationPromo() {
      if (this.amountPromo < this.itemsPromo.length) {
        this.amountPromo = this.itemsPromo.length;
        return true;
      } else return false;
    },
    validationAudience() {
      if (this.amountAudience < this.itemsAudience.length) {
        this.amountAudience = this.itemsAudience.length;
        return true;
      } else return false;
    },
    searchAddons() {
      if (this.finalSearch !== "") {
        let keywords = getArrayString(this.finalSearch);
        this.actionKeywordsSearch(keywords);
      }
    },
    allGallery() {
      this.findAddonsType({
        type: "contest",
        take: this.take,
        page: this.pageContest,
      });
      this.findAddonsType({
        type: "survey",
        take: this.take,
        page: this.pageSurvey,
      });
      this.findAddonsType({
        type: "promo",
        take: this.take,
        page: this.pagePromo,
      });
      this.findAddonsType({
        type: "audience",
        take: this.take,
        page: this.pageAudience,
      });
      (this.itemsContest.length > 5) ? this.arrowContest = 'always' : this.arrowContest = false;
      (this.itemsSurvey.length > 5) ? this.arrowSurvey = 'always' : this.arrowSurvey = false;
      (this.itemsPromo.length > 5) ? this.arrowPromo = 'always' : this.arrowPromo = false;
      (this.itemsAudience.length > 5) ? this.arrowAudience = 'always' : this.arrowAudience = false;
    },
    actionKeywordsSearch(keywords) {
      this.$store.dispatch("addons/folders/FIND_ADDONS_BY_KEYWORDS", {
        take: 50,
        page: 1,
        keywords,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.content__items {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  // height: 864px;
  padding: 30px 21px;
  padding-bottom: 125px;
  height: calc(100vh - 120px);
  margin-top: 120px;
}

.h5-title-addon {
  font-size: 15px;
  font-weight: bold;
  line-height: 16px;
}
.content__items .is-empty {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 281px;
  width: 100%;
  font-size: 20px;
  font-weight: 500;
  line-height: 16px;
  color: #222222;
}
</style>
