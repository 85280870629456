<template>
  <div class="container-preview" :style="{ backgroundColor: design.backcolor }">
    <div class="container-preview-main">
      <div class="preview-general">
        <preview-background :image="image" />
        <preview-logo :message="message" />
        <div class="pa-5 pt-6">
          <preview-questions
            v-for="question in questions"
            :key="question.id"
            :question="question"
          />
        </div>
        <div class="d-flex justify-center">
          <v-btn
            :color="design.buttoncolor"
            class="mb-4 btn-preview"
            :href="message.button.url"
            target="_blank"
          >
            <span :style="{ color: design.buttontextcolor }">
              {{ message.button.name }}
            </span>
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  PreviewBackground,
  PreviewLogo,
  PreviewQuestions,
} from "@design/styleguide";
export default {
  name: "PreviewModule",
  components: {
    PreviewBackground,
    PreviewLogo,
    PreviewQuestions,
  },
  computed: {
    questions() {
      return this.$store.getters["addons/create/questions"];
    },
    message() {
      return this.$store.getters["addons/create/message"];
    },
    design() {
      return this.$store.getters["addons/create/design"];
    },
    image() {
      const {
        id,
        thumbnails,
        width,
        height,
        x,
        y,
        transform: { scale, rotate },
        locked,
        cropped,
      } = this.$store.getters["addons/create/addon"];

      if (!thumbnails) return {};

      const currentImage = thumbnails.find(
        (item) => item.quality === "SCREEN_4"
      );

      // console.log(scale, 'ooo')
      const flip = `scale(${scale.x}, ${scale.y})`;
      const translate = `translate(${x}px,${y}px)`;
      const rotateDeg = `rotate(${rotate}deg)`;

      const transform = [translate, rotateDeg, flip];
      return {
        id,
        styles: {
          width: width + "px",
          height: height + "px",
          transform: transform.join(" "),
          // transition: '.4s '
        },
        stylesCropped: {
          width: cropped.width + "px",
          height: cropped.height + "px",
          transform: `translate(${cropped.x}px,${cropped.y}px)`,
        },
        locked,
        path: currentImage.location,
      };
    },
  },
  mounted() {
    console.log("preview", this.design);
  },
};
</script>

<style scoped lang="scss">
.container-preview {
  width: 100%;
  height: calc(100vh - 116px);
  display: flex;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 5px;
  &-main {
    overflow-x: hidden;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 2px;
    }
    /* Track */
    &::-webkit-scrollbar-track {
      background: #fff;
    }
    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #c5d0e4;
    }
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #888;
    }
    .preview-general {
      width: 491px;
      border-color: #ffffff;
      background-color: #ffffff;
    }
  }
  .btn-preview {
    background-color: #00a5ff;
    box-shadow: 0px 2px 4px rgba(44, 56, 82, 0.1);
    border-radius: 3px;
    span {
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;
    }
  }
}
</style>
