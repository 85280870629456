<template>
  <v-row @click="setSelect(questionItem.id, questionIndex)">
    <div class="wrap__question__module">
      <div class="wrap__question__module__drag">
        <v-row style="padding: 10px" justify="end"
          ><v-icon small color="#5D6B88" style="cursor: pointer"
            >mdi-cursor-move</v-icon
          >
        </v-row>
        <v-row style="padding: 10px" justify="end">
          <v-icon
            small
            color="#5D6B88"
            @click="deleteQuestion(questionItem.id)"
            style="cursor: pointer"
            >mdi-trash-can-outline
          </v-icon></v-row
        >
      </div>
      <div class="col box-question-container ignore-elements px-5">
        <v-row no-gutters>
          <v-col
            cols="12"
            style="padding: 0; justify-content: center; align-items: center"
          >
            <v-row class="pt-0 px-5">
              <header-question
                questionName="What would you like to ask?"
              ></header-question>
            </v-row>
            <v-row class="input-container anwser--container px-5">
              <v-text-field
                placeholder="Your question text goes here"
                solo
                hide-details
                class="input-question"
                v-model="questionItem.question"
                @keyup="setQuestionByItem(questionItem.id, questionItem.question)"
              ></v-text-field>
            </v-row>
            <v-row class="px-5">
              <input-checkbox-question :numberRange="questionItem.rangeReaction" :rangeType="questionItem.reaction"></input-checkbox-question>
            </v-row>
            <v-row class="px-5 mb-2">
              <v-col cols="12" class="px-0 d-flex justify-space-between">
                <div class="range-container">
                  <v-text-field
                    placeholder="Set low range level"
                    solo
                    dense
                    class="input-low-range range-answer"
                    v-model="questionItem.lowrange"
                    @keyup="
                      setLowRangeByItem(questionItem.id, questionItem.lowrange)
                    "
                  ></v-text-field>
                </div>
                <div class="range-container">
                  <v-text-field
                    placeholder="Set high range level"
                    solo
                    dense
                    class="input-high-range range-answer"
                    v-model="questionItem.highrange"
                    @keyup="
                      setHighRangeByItem(questionItem.id, questionItem.highrange)
                    "
                  ></v-text-field>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
    </div>
  </v-row>
</template>

<script>
import HeaderQuestion from "./HeaderQuestion.vue";
import InputCheckboxQuestion from "./InputCheckboxQuestion.vue";
export default {
  name: "AnswerRange",
  components: {
    HeaderQuestion,
    InputCheckboxQuestion,
  },
  props: {
    element: {
      type: Object,
      default: () => {
        return {}; 
      },
    },

    questionItem: {
      type: Object,
      required: true,
    },
    questionIndex: {
      type: Number,
      required: true,
    },
    questionSelected: {
      type: Number,
      required: true,
    },
    numberQuestion: {
      type: Number,
      default: 0,
    },
    items: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data(){
    return {
      numberRange: 1,
      rangeType:{},
      itemFronStore:{},
    }
  },
  methods: {
    setSelect(id, index) {
      this.$emit("set-select", id, index);
    },
    deleteQuestion(id_question) {
      this.$emit("delete-item", id_question);
    },
    setQuestionByItem(index, text) {
      this.$emit("set-question-by-item", index, text);
    },
    setHighRangeByItem(id_question, x) {
      this.$emit("set-high-range-by-item", id_question, x);
    },
    setLowRangeByItem(id_question, x) {
      this.$emit("set-low-range-by-item", id_question, x);
    },
  },
};
</script>
<style lang="scss" scoped>
.box-question-container {
  border: 1px solid #c5d0e4;
}
.input-container {
  .input-question {
    border: 1px solid #c5d0e4;
    border-radius: 3px;
    ::v-deep .v-input__control {
      min-height: 35px;
      max-height: 35px;
      font-size: 12px;
      line-height: 16px;
      border-radius: 3px;
      .v-input__slot {
        box-shadow: inset 0px 2px 5px rgba(197, 208, 228, 0.62) !important;
        // box-shadow: none;
        input {
          &::placeholder {
            color: #5d6b88;
          }
        }
      }
    }
  }
}
.range-container {
  .range-answer {
    border: 1px solid #c5d0e4;
    border-radius: 0;
    background-color: #fff;
    width: 118px !important;
    ::v-deep .v-input__control {
      min-height: 24px;
      max-height: 24px;
      font-size: 10px;
      line-height: 16px;
      color: #5d6b88;
      .v-input__slot {
        box-shadow: none;
        input{
          padding-top: 2px !important;
          &::placeholder {
            color: #5d6b88;
          }
        }
      }
    }
  }
}
</style>
