<template>
  <v-row v-if="questionSelectIndex > -1 && element.question.length > 0">
    <v-col cols="12" class="question">
      <!-- {{ element.question[questionSelectIndex] }} -->
      <p class="question-title common-text">Answer Type</p>
      <combobox-icon
        :items="items"
        :height="35"
        @selected-item="selectedItem"
        font-weight="font-weight-bold"
        :value="getName(element.question[questionSelectIndex].type)"
        size-icon="16px"
      />
      <v-row style="margin-top: 20px" v-if="typeSelected == 3">
        <v-col cols="4">
          <small-combobox
            :items="cants"
            max-width="55px"
            :value="element.question[questionSelectIndex].rangeReaction"
            @selected-item="selectedRangeReaction"
          />
        </v-col>
        <v-col cols="8" style="padding-left: 0px">
          <combobox-icon
            :height="35"
            :items="typeRanges"
            class-icon="class-icon-reactions"
            @selected-item="selectedReaction"
            :innerIcon="prevIcon"
            size-icon="16px"
            :value="
              getNameReaction(element.question[questionSelectIndex].reaction)
            "
          />
        </v-col>
      </v-row>
      <div class="question-buttons">
        <!-- Required -->
        <div class="question-required">
          <div class="question-required-title">
            <p>Required</p>
          </div>
          <v-switch
            dense
            class="question-required-switch"
            v-model="element.question[questionSelectIndex].required"
            inset
            @change="
              updateRequired(
                'r',
                element.question[questionSelectIndex].required
              )
            "
          ></v-switch>
        </div>
        <!-- Qr-code -->
        <cp-input-text
          v-if="typeSelected == 5 ? generateQR(element.question[questionSelectIndex].qr.url): false"
          label="Add your QR code"
          placeholder="Copy your URL here"
          v-model="element.question[questionSelectIndex].qr.url"
        />
				<p 
					v-if="isValidate && typeSelected == 5" 
					class="validation-qr"
				>
					{{ textValidation }}
				</p>
        <!-- Other Option -->
        <div class="question-required" v-if="typeSelected === 1">
          <div class="question-required-title">
            <p>“Other” option</p>
          </div>
          <v-switch
            dense
            class="question-required-switch"
            v-model="element.question[questionSelectIndex].other"
            inset
            @change="
              updateRequired('o', element.question[questionSelectIndex].other)
            "
          >
          </v-switch>
        </div>
      </div>
    </v-col>
  </v-row>
  <v-row v-else>
    <v-col>
      <p class="body-text default-text">Add a question to start</p>
    </v-col>
  </v-row>
</template>

<script>
import ComboboxIcon from "@/components/comboboxes/ComboboxIcon.vue";
import CpInputText from "@/components/inputs/CpInputText.vue";
import SmallCombobox from "@/components/comboboxes/SmallCombobox.vue";
import { validationUrl } from "@design/styleguide";
import QRCode from "qrcode";
export default {
  name: "SideBarQuestion",
  components: {
    ComboboxIcon,
    CpInputText,
    SmallCombobox,
  },
  props: {
    element: {
      type: Object,
      default: () => {
        return {};
      },
    },
    questionSelectIndex: {
      type: Number,
      default: -1,
    },
    items: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      typeRanges: [
        { id: 1, icon: "mdi-star", name: "stars" },
        { id: 2, icon: "mdi-heart-outline ", name: "hearts" },
        { id: 3, icon: "mdi-thumb-up-outline ", name: "likes" },
        { id: 4, icon: "mdi-check ", name: "checks" },
        { id: 5, icon: "mdi-sort-numeric-ascending ", name: "numbers" },
      ],
      cants: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      select: "",
      textLink: '',
      typeSelected: 0,
      prevIcon: "",
      isSelected: false,
      isValidate: false,
			textValidation: ''
    };
  },
  methods: {
    setOtherAwnser(e){
      let el = this.element.question[this.questionSelectIndex];
      let otherAnswer = {
        id : el.answers.length + 1,
        state: false,
        text: '',
        other: true,
      }
      let find_ = el.answers.find((x)=> x.other === true);
      if (e) if (find_ == undefined) el.answers.push(otherAnswer);
      if(!e) {
        el.other_answer = '';
        el.answers = el.answers.filter(x=>x.id !== find_.id);
        }
    },
    updateRequired() {
      let el = this.element.question[this.questionSelectIndex];
      if (el.type === 1) this.setOtherAwnser(el.other);
      this.$store.commit("addons/create/SET_QUESTIONS", this.element.question);
    },
    createItemQuestion(type) {
      this.$emit("type-question", type);
    },
    selectedItem(item) {
      this.typeSelected = item.id;
      this.$store.dispatch("addons/create/CHANGE_TYPE_QUESTION", {
        element: this.element.question[this.questionSelectIndex],
        type: item.id,
      });
      this.prevIcon = "";
      this.isSelected = false;
    },
    getName(type) {
      const selectedItem = this.items.find((el) => el.id === type);
      this.typeSelected = selectedItem.id;
      return selectedItem.name;
    },
    selectedReaction(item) {
      this.isSelected = true;
      this.prevIcon = item.icon;
      this.$store.dispatch("addons/create/SET_RANGE_REACTION", {
        item,
        id: this.element.question[this.questionSelectIndex].id,
      });
    },
    selectedRangeReaction(range) {
      this.$store.dispatch("addons/create/SET_RANGE_NUMBER_REACTION", {
        range,
        id: this.element.question[this.questionSelectIndex].id,
      });
    },
    getNameReaction(item) {
      let elementSelected = this.element.question[this.questionSelectIndex];
      if (!this.isSelected) this.prevIcon = item.icon;
      elementSelected.reaction.name = item.name;
      elementSelected.reaction.icon = item.icon;
      this.updateRequired();
      // console.log('---->',item);
      return item.name;
    },
    async generateQR(e) {
			if(e === null) return;
			const data = validationUrl(e);
			if(!data.resp){ 
				this.isValidate = true; 
				this.textValidation = data.text;
				return 
			}
			this.textValidation = data.text;
			this.isValidate = false;
      const text = e.trim();
      if (text !== "") {
        try {
          const code = await QRCode.toDataURL(text);
          this.addQrCode(
            code,
            this.element.question[this.questionSelectIndex].id
          );
          return false;
        } catch (err) {
          console.error(err);
        }
      }
      this.addQrCode(null, this.element.question[this.questionSelectIndex].id);
    },
    addQrCode(code, id) {
      this.$store.dispatch("addons/create/SET_QR_CODE", { code, id });
    },
  },
};
</script>

<style lang="scss" scoped src="./SideBarQuestion.scss"></style>
