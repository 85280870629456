<template>
	<div class="d-flex justify-center justify-lg-end">
		<v-sheet
			class="button-container"
			v-if="elementsaved && elementsaved.path"
		>
			<div class="post-container">
				<div 
					:style="{
							width: element.width + 'px', 
							height: element.height + 'px',
							transform: `translate(${element.x}px, ${element.y}px) rotate(${element.transform.rotate}deg) scale(${element.transform.scale.x}, ${element.transform.scale.y})`,
							overflow: 'hidden'
						}"	
				>
					<div
						:style="{
							width: element.cropped.width + 'px', 
							height: element.cropped.height + 'px',
							transform: `translate(${element.cropped.x}px, ${element.cropped.y}px) rotate(${element.transform.rotate}deg)`
						}"	
					>
						<img
							class="post-container__background"
							:src="elementsaved.path"
										
						/>
						
					</div>
				</div>

				<div class="post-container__actions">
					<v-btn
						tile
						small
						class="post-container__actions-edit"
						width="38"
						height="34"
						min-width="34"
						@click="openEditImageDialog"
					>
						<v-icon size="16">fas fa-edit</v-icon>
					</v-btn>

					<v-btn
						icon
						color="white"
						class="post-container__actions-close"
						@click="resetBackground"
					>
						<v-icon>fas fa-times</v-icon>
					</v-btn>
				</div>
				
			</div>

			<EditImageDialog />
				
		</v-sheet>
	</div>
</template>

<script>
import EditImageDialog from '@/components/editImage/EditImageDialog';

export default {
	name: 'EditImage',
	components: {
		EditImageDialog
	},
	props: {
		element:{
			type: Object,
			default: () => {
				console.log('0');
				return {}
			}
		}, 
		addonLoading:{
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			dialog2: false,
			select: 1,
			tool1: true,
			tool3: false,
			cropFlag: false,	
			cropEdit: false,
			flipVert: false,	
			flipHori: false,
			styleCustom: "",
			item: "100%",
			zoomItem: '',
			zoomItems: ["25%", "50%", "80%", "100%", "125%", "150%", "200%"],
			/* zoomItems: [
				{id: 0.25, name: '25%'},
				{id: 0.50, name: '50%'},
				{id: 0.80, name: '80%'},
				{id: 1, name: '100%'},
				{id: 1.25, name: '125%'},
				{id: 1.5, name: '150%'},
				{id: 2, name: '200%'},
			], */
			items: [
      { id: 1, title: "Checkboxes", icon: "mdi-format-list-checks " },
      { id: 2, title: "Radio buttons", icon: "mdi-format-list-checks " },
      { id: 3, title: "Range", icon: "mdi-battery-low" },
      { id: 4, title: "Open text", icon: "mdi-layers-triple " },
      { id: 5, title: "QR CODE", icon: "mdi-qrcode" },
      {
        id: 6,
        title: "Social network (iframe)",
        icon: "mdi-thumb-up-outline "
      },
      { id: 7, title: "Date", icon: "mdi-calendar-month" }
    ],
		};
	},
	computed: {
		elementsaved() {
      return this.$store.getters["addons/create/addon"];
    },
	},
	methods: {
		openEditImageDialog() {
      this.$store.dispatch("modals/open", "editImage");
    },
		resetBackground() {
			this.$store.dispatch('addons/create/REMOVE_BACKGROUND_IN_ADDON')
    },
	},
};
</script>

<style lang="scss" scoped src="./EditImage.scss"></style>