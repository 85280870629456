<template>
  <v-slide-group
    v-model="value"
    class="arrow-skyblue addonItemSlide"
    center-active
    :show-arrows="showArrows"
    @click:next="nextData"
  >
    <v-slide-item v-for="item in items" :key="item.id" class="slideItem">
      <v-card class="itemCard" width="209" style="margin-right: 20px">
        <v-img
          max-width="209"
          max-height="209"
          aspect-ratio="1"
          :src="item.finalImage"
          contain
          @click="redirectStart(item)"
          style="cursor: pointer"
        ></v-img>
        <v-card-text>
          <v-sheet
            style="cursor: pointer"
            @click="redirectStart(item)"
            height="auto"
            class="mt-1"
          >
            <div class="card-header-design">
              {{ item.name }}
            </div>
            <div class="card-date-design">
              {{ getStringDate(item.createdAt) }}
            </div>
          </v-sheet>
        </v-card-text>
      </v-card>
    </v-slide-item>
  </v-slide-group>
</template>
<script>
import { transformStringDate } from "@design/styleguide";
export default {
  name: "CpCardContent",
  components: {},
  props: {
    items: {
      type: Array,
      default: () => {
        return [];
      },
    },
    showArrows: {
      type: [Boolean, String],
      default: () => {
        return false;
      },
    },
    value: {
      type: [String, Number],
      default: "",
    },
  },
  data: () => ({
    //model: null,
  }),
  methods: {
    async redirectStart(item) {
      const image = {
        id: 1,
        width: 300,
        height: 300,
        path: item.image,
        //w: 300,
        //h: 400,
        draggable: false,
        resizable: true,
        top: "auto",
        left: "auto",
        transform: "",
      };
      this.$store.commit("addons/create/SET_BACKGROUND_ADDON", image);
      item.id = null;
      item.uuid = null;
      console.log(" item ", item);
      this.$store.dispatch("addons/create/SET_EDIT_ADDON", item);
      this.$router.push("/addon/create-addon");
    },
    nextData() {
      this.$emit("trigger-charge", this.value);
    },
    getStringDate(date) {
      return transformStringDate(date, "MM-DD-YYYY");
    },
  },
};
</script>
<style scoped lang="scss">
.h5-title-addon {
  font-size: 15px;
  font-weight: 700;
  line-height: 16px;
}
.card-title-design {
  font-family: Montserrat;
  font-size: 12px !important;
  font-weight: 900 !important;
  line-height: 20px !important;
  color: #ea9a41;
}
.card-title-design > span {
  font-weight: 300;
}
.card-subtitle-design {
  font-family: Oxygen;
  font-size: 8px;
  font-weight: 700;
  line-height: 20px;
  color: black;
}
.card-description-design {
  font-family: Oxygen;
  font-size: 6px;
  font-weight: 400;
  line-height: 20px;
}
.card-header-design {
  text-align: left;
  font-size: 15px;
  line-height: 16px;
  font-weight: bold;
  margin-bottom: 4px;
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  max-height: 16px;
}
.card-date-design {
  font-weight: normal;
  font-size: 10px;
  line-height: 16px;
  text-align: left;
}
.align-vertical-design {
  height: 50%;
  display: flex;
  align-items: center;
}
.arrow-skyblue > .v-slide-group__prev > i,
.arrow-skyblue > .v-slide-group__next > i {
  color: #00a5ff !important;
}
/* .arrow-skyblue.v-item-group{
		position: sticky;
	} */
.arrow-skyblue {
  height: 281px;
}
.arrow-skyblue > .v-slide-group__wrapper{
  contain: inherit !important;
  overflow: inherit !important;
}
.itemCard{
  box-shadow: 0px 2px 6px 2px rgba(44, 56, 82, 0.1) !important;
  border-radius: 6px;
}
</style>
