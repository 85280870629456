<template>
  <v-row @click="setSelect(questionItem.id, questionIndex)" class="answer-date">
    <div class="wrap__question__module">
      <div class="wrap__question__module__drag">
        <v-row>
          <v-col class="answer-date__cursor">
            <v-icon small color="#5D6B88" style="cursor: pointer">
              mdi-cursor-move
            </v-icon>
          </v-col>
        </v-row>
        <v-row justify="end" style="padding: 10px">
          <v-icon
            small
            color="#5D6B88"
            style="cursor: pointer"
            @click="deleteQuestion(questionItem.id)"
            >mdi-trash-can-outline
          </v-icon></v-row
        >
      </div>
      <div class="col box-question-container px-9">
        <v-row no-gutters>
          <v-col cols="12" class="answer-date__box">
            <v-row>
              <v-col class="px-0 py-0">
                <header-question
                  questionName="What would you like to ask?"
                ></header-question>
              </v-col>
            </v-row>
            <v-row class="input-container">
              <v-col class="px-0 py-0">
                <v-text-field
                  placeholder="Your question text goes here"
                  solo
                  hide-details
                  class="input-question"
                  v-model="questionItem.question"
                  @keyup="
                    setQuestionByItem(questionItem.id, questionItem.question)
                  "
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="px-0 question-answers-container">
                <v-text-field
                  placeholder="MM/DD/AAAA"
                  solo
                  readonly
                  dense
                  background-color="#f3f4f7"
                  class="question-answer"
                  v-model="computedDateFormatted"
                  @click="setDate()"
                ></v-text-field>
                <v-date-picker v-if="datepicker" v-model="date" no-title>
                </v-date-picker>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
    </div>
  </v-row>
</template>

<script>
import HeaderQuestion from "./HeaderQuestion.vue";
export default {
  name: "AnswerDate",
  components: {
    HeaderQuestion,
  },
  data() {
    return {
      date: "",
      dateFormatted: "",
      datepicker: false,
    };
  },
  computed: {
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
  },
  watch: {
    computedDateFormatted(strDate) {
      this.$emit("set-date-by-item", this.questionItem.id, strDate);
    },
  },
  props: {
    element: {
      type: Object,
      default: () => {
        return {};
      },
    },
    questionItem: {
      type: Object,
      required: true,
    },
    questionIndex: {
      type: Number,
      required: true,
    },
    questionSelected: {
      type: Number,
      required: true,
    },
    numberQuestion: {
      type: Number,
      default: 0,
    },
    items: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  mounted() {
    // setting dates
    const date = this.questionItem.date;
    this.date = this.parseDate(date);
  },
  methods: {
    setSelect(id, index) {
      this.$emit("set-select", id, index);
    },
    deleteQuestion(id_question) {
      this.$emit("delete-item", id_question);
    },
    setQuestionByItem(index, text) {
      this.$emit("set-question-by-item", index, text);
    },
    setDate() {
      //this.datepicker = !this.datepicker;
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;
      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.answer-date {
  &__cursor {
    padding-right: 4px;
    text-align: center;
  }
  .box-question-container {
    border: 1px solid #c5d0e4;
  }
  &__box {
    padding: 0px;
    justify-content: center;
    align-items: center;
  }
  &__box-date {
    /* background-color: #f3f4f7;
			border-radius: 3px; */
    font-weight: 400;
    font-size: 12px;
  }
.input-container {
  .input-question {
    border: 1px solid #c5d0e4;
    border-radius: 3px;
    ::v-deep .v-input__control {
      min-height: 35px;
      max-height: 35px;
      font-size: 12px;
      line-height: 16px;
      border-radius: 3px;
      .v-input__slot {
        box-shadow: inset 0px 2px 5px rgba(197, 208, 228, 0.62) !important;
        // box-shadow: none;
        input {
          &::placeholder {
            color: #5d6b88;
          }
        }
      }
    }
  }
}
  .question-answers-container {
    .question-answer {
      box-shadow: inset 0px 2px 5px rgba(197, 208, 228, 0.62);
      border-radius: 3px;
      ::v-deep .v-input__control {
        max-height: 35px;
        font-weight: 400;
        font-size: 12px;
        color: #5d6b88;
        .v-input__slot {
          background-color: #f3f4f7;
          box-shadow: none;
          fieldset {
            border-color: #f3f4f7;
          }
        }
      }
    }
  }
}
</style>
