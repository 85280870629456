<template>
  <div class="edit-image__selector-cropped" :style="imageStyles"><!-- :style="imageCropped"-->
    <div class="edit-image__selector-resize" :style="imageCropped" :class="opacity ? 'background-opacity' : ''">
      <img :src="image.path" style="width:100%;height:100%;"/>
    </div>
  </div>
</template>

<script>
export default {
	name:"EditImageBackground",
  props: {
    contrast: {
      type: Boolean,
      default: false
    },
    image: {
      type: Object,
      default: Object
    },
    imageCropped: {
      type: Object,
      default: Object
    },
    opacity: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    imageStyles() {
      return {
        ...this.image.styles,
        filter: this.contrast ? 'contrast(0.5)' : null
      }
    },
    imageCropped() {
      // INFO DEL ELEMENTO CORTADO
      // width: ANCHO CORTADO
      // height: ALTO CORTADO
      // x: UBICACION X
      // Y: UBICACION Y

      // DATOS ORIGINALES
      // WIDTH: ORIGINAL
      // HEIGHT: ORIGINAL
      // X: TOMANDO COMO PUNTO DE ORIGEN EL CORTAOD
      // Y: TOMANDO COMO PUNTO DE ORIGEN EL CORTAOD

      const { width, height, x, y, cropped, transform: { rotate } } = this.$store.getters["addons/create/addon"];


      const translate = `translate(${cropped.x}px,${cropped.y}px)`;
      const rotateDeg = `rotate(${rotate}deg)`;
      const transform = [translate, rotateDeg];
      return {
        width: `${cropped.width}px`, 
        height: `${cropped.height}px`,
        transform: transform.join(" ")
      }
    }
  }
}
</script>
<style lang="scss" scoped>
  .edit-image__selector-resize.background-opacity {
    position: relative;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0,0,0,.5);
    }
  }
  .edit-image__selector-cropped {
    overflow: hidden;
  }
</style>