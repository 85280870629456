<template>
  <div class="edit-image__canvas-selector">
    <div class="edit-image__resize-tools-bg" :style="image.styles">
      <div class="edit-image__background" :style="imageStylesBackgroundCropped">
        <img :src="image.path" style="width:100%;height:100%;"/>
      </div>
    </div>
    
    <div class="edit-image__resize-tools" :style="image.styleTools">
      <div
        class="edit-image__resize-tl"
        @pointerdown="resize($event, 'top-left')"
      ></div>
      <div
        class="edit-image__resize-tr"
        @pointerdown="resize($event, 'top-right')"
      ></div>
      <div
        class="edit-image__resize-bl"
        @pointerdown="resize($event, 'bottom-left')"
      ></div>
      <div
        class="edit-image__resize-br"
        @pointerdown="resize($event, 'bottom-right')"
      ></div>

      <div class="edit-image__horizontal">
        <div class="edit-image__horizontal-line"></div>
        <div class="edit-image__horizontal-line"></div>
      </div>

      <div class="edit-image__vertical">
        <div class="edit-image__vertical-line"></div>
        <div class="edit-image__vertical-line"></div>
      </div>

      <div
        class="edit-image__resize-box"
        @pointerdown="movePosition($event)"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
	name:"EditImageSelectorCrop", 
  data() {
    return {
      clicked: false,
      mouseMovement: {
        x: 0,
        y: 0,
      },
      selectedCorner: null,
      size: {
        width: 0,
        height: 0,
      },
      position: {
        x: 0,
        y: 0
      },
      sizeAvailable: {
        width: 0,
        height: 0
      },
      positionCroppedBackground: {
        x: 0,
        y: 0
      },
      currentImageCropSize: {
        x: 0,
        y: 0,
        width: 0,
        height: 0
      }
    };
  },
  computed: {
    imageStylesBackgroundCropped() {
      const {
        width,
        height,
        cropped,
        transform: {
          rotate
        }
      } = this.$store.getters["addons/create/addon"];
      const translate = `translate(${(cropped.x)}px,${(cropped.y)}px)`;
      // const rotateDeg = `rotate(${rotate}deg)`;
      const transform = [translate];
      return {
        width: cropped.width + "px",
        height: cropped.height + "px",
        transform: transform.join(" "),
      }
    },
    image() {
      const {
        id,
        thumbnails,
        width,
        height,
        x,
        y,
        cropped,
        transform: { rotate, scale },
      } = this.$store.getters["addons/create/addon"];
      const currentImage = thumbnails.find(
        (item) => item.quality === "SCREEN_4"
      );

      const translate = `translate(${x}px,${y}px)`;
      const rotateDeg = `rotate(${rotate}deg)`;
      const flip = `scale(${scale.x}, ${scale.y})`;
      const transform = [translate, rotateDeg, flip];
      const transformTools = [translate, rotateDeg];
      return {
        id,
        width,
        height,
        x,
        y,
        cropped,
        styles: {
          width: width + "px",
          height: height + "px",
          transform: transform.join(" "),
        },
        styleTools: {
          width: width + "px",
          height: height + "px",
          transform: transformTools.join(" "),
        },
        path: currentImage.location,
      }
    },
    cropped() {
      const { cropped: { width, height, x, y } } = this.$store.getters["addons/create/addon"];
      return {
        width,
        height,
        x,
        y
      }
    }
  },
  created() {
    this.position.x = this.image.x;
    this.position.y = this.image.y;
    this.size.width = this.image.width;
    this.size.height = this.image.height;

    this.currentImageCropSize = {
      x: this.image.cropped.x,
      y: this.image.cropped.y,
      width: this.image.cropped.width,
      height: this.image.cropped.height
    }
    this.sizeAvailable.width = this.image.cropped.width;
    this.sizeAvailable.height = this.image.cropped.height;
    this.positionCroppedBackground.x = this.image.cropped.x;
    this.positionCroppedBackground.y = this.image.cropped.y;
  },
  methods: {
    resize(event, corner) {
      this.clicked = true;
      this.selectedCorner = corner;
      this.mouseMovement.x = event.clientX;
      this.mouseMovement.y = event.clientY;

      window.addEventListener("pointermove", this.onMouseMove);
      window.addEventListener("pointerup", this.onMouseUp);
    },
    onMouseMove(event) {
      if (this.clicked && event.buttons == 1) { 
        let position = { // posicion actual de lo cortado
          x: this.position.x,
          y: this.position.y,
        };
        let width = this.size.width;
        let height = this.size.height;

        const clientX = (width >= 50 || height >= 50)  ? event.clientX : 0;
        const clientY = (width >= 50 || height >= 50)  ? event.clientY : 0;
        
        let movedMouseX = this.mouseMovement.x - clientX;
        let movedMouseY = this.mouseMovement.y - clientY;

        const remainingSize = {
          width: this.image.cropped.width - this.size.width, // TAMAÑO DEL PADRE - TAMAÑO DE LO CORTADO 
          height: this.image.cropped.height - this.size.height
        }

        const positionAvailable = {
          x: this.image.x + remainingSize.width + this.image.cropped.x,
          y: this.image.y + remainingSize.height + this.image.cropped.y,
        }

        switch (this.selectedCorner) {
          case "top-left":
            width = this.size.width + movedMouseX;
            height = this.size.height + movedMouseY;

            if((width <= 50 || height <= 50)) return;

            position.x -= movedMouseX;
            position.y -= movedMouseY;
            break;
          case "top-right":
            height = this.size.height + movedMouseY;
            if ((this.size.width - movedMouseX) <= this.sizeAvailable.width) width = this.size.width - movedMouseX;
            if((width <= 50 || height <= 50)) return;
            position.y -= movedMouseY;
            break;
          case "bottom-left":
            width = this.size.width + movedMouseX;
            if ((this.size.height - movedMouseY) <= this.sizeAvailable.height ) height = this.size.height - movedMouseY;
            if((width <= 50 || height <= 50)) return;
            position.x -= movedMouseX;
            break;
          case "bottom-right":
            
            if ((this.size.width - movedMouseX) <= this.sizeAvailable.width ) width = this.size.width - movedMouseX;
            if ((this.size.height - movedMouseY) <= this.sizeAvailable.height ) height = this.size.height - movedMouseY;
            break;
          default:
            
            if((position.x - movedMouseX) <= positionAvailable.x) position.x -= movedMouseX;
            if((position.y - movedMouseY) <= positionAvailable.y) position.y -= movedMouseY;
        }

        this.mouseMovement.x = event.clientX;
        this.mouseMovement.y = event.clientY;
        
        if(position.x >= (this.image.x + this.image.cropped.x)) this.position.x = position.x;
        if(position.y >= (this.image.y + this.image.cropped.y)) this.position.y = position.y;

        this.sizeAvailable.width = this.image.cropped.width - this.position.x;
        this.sizeAvailable.height = this.image.cropped.height - this.position.y;
        
        // ANCHO PEQUEÑO + (POSICION PEQUEÑA - POSICION PADRE)

        this.positionCroppedBackground.x = (this.position.x - this.image.x - this.image.cropped.x) * -1
        this.positionCroppedBackground.y = (this.position.y - this.image.y - this.image.cropped.y) * -1
        
        
        if (width <= 50 || height <= 50 ) return;

        if(position.x >= (this.image.x + this.image.cropped.x)) this.size.width = width;
        if(position.y >= (this.image.y + this.image.cropped.y)) this.size.height = height;    
        
        this.$store.dispatch('addons/create/CHANGE_POSITION_IN_CROP', {
          x: this.positionCroppedBackground.x,
          y: this.positionCroppedBackground.y
        });

        this.$store.dispatch("addons/create/CHANGE_POSITION_IN_IMAGE", {
          x: this.position.x,
          y: this.position.y,
        });

        this.$store.dispatch("addons/create/CHANGE_SIZE_IN_IMAGE", {
          width,
          height,
        });
        
      }
    },
    onMouseUp() {
      this.clicked = false;
      window.removeEventListener("pointermove", this.onMouseMove);
      window.removeEventListener("pointerup", this.onMouseUp);

      this.$store.dispatch('addons/editimage/SAVE_CHANGES');
    },
    movePosition() { 
      this.selectedCorner = null;
      this.clicked = true;
      window.addEventListener("pointermove", this.onMouseMove);
      window.addEventListener("pointerup", this.onMouseUp);
    },
  },
};
</script>
<style lang="scss" scoped>
.edit-image {
  &__canvas-selector {
    height: 256px;
    width: 491px;
    position: absolute;
  }

  &__resize {
    &-tools-bg {

      overflow: hidden;
      position: absolute;
    }
    &-box {
      border: 1.5px solid #00a5ff;
      position: absolute;
      top: 0px;
      left: 0px;
      bottom: 0px;
      right: 0px;
      z-index: 10;
      cursor: move;
    }
    &-tl,
    &-tr,
    &-bl,
    &-br {
      height: 17px;
      width: 17px;
      pointer-events: auto;
      // background-color: #00a5ff;
      background: url('~@/assets/cut-corner.png');
      position: absolute;

      z-index: 20;
    }
    &-tl {
      left: 0;
      top: 0;
      cursor: nw-resize;
    }

    &-tr {
      right: 0;
      top: 0;
      cursor: ne-resize;
      transform: rotate(90deg);
    }

    &-bl {
      bottom: 0;
      left: 0;
      cursor: ne-resize;
      transform: rotate(270deg);
    }

    &-br {
      bottom: 0;
      right: 0;
      cursor: nw-resize;
      transform: rotate(180deg);
    }
  }

  &__horizontal, &__vertical {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-evenly;
  }

  &__horizontal {
    flex-direction: column;
    &-line {
      height: 1px;
      width: 100%;
      background-color: #00a5ff;
    }
  }

  &__vertical {
    &-line {
      width: 1px;
      height: 100%;
      background-color: #00a5ff;
    }
  }
}
</style>