<template>
  <v-col cols="12" style="padding-left: 0" class="abc pb-2">
    <h3 class="text-header-question title-upload dark-blue-ds text-left">
      {{ this.questionName }}
    </h3>
  </v-col>
</template>

<script>
export default {
  name: "HeaderQuestion",
  props: {
    questionName: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.text-header-question {
  text-align: left !important;
  color: #2C3852 !important;
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
}
</style>
