<template>
  <v-footer class="d-flex justify-space-between" color="transparent">
    <div class="d-flex justify-center align-center">
      <v-icon size="22" class="mr-1" @click="zoomTransform(count - 1)">mdi-minus</v-icon>
      <select-input
        :items="items"
        v-model="zoom.transform"
        text="name"
        id="id"
        @input="zoomChange(zoom)"
      />
      <v-icon size="22" class="ml-1" @click="zoomTransform(count + 1)">mdi-plus</v-icon>
    </div>
    <div style="display: flex">
      <v-btn
      color="primary"
        elevation="2"
        @click="closeDialog()"
      >Save</v-btn>
      <!--  ADD Button Save Image -->
    </div>
  </v-footer>
</template>

<script>
import { SelectInput } from "@design/styleguide";
export default {
	name:"EditImageFooter",
  components: {
    SelectInput
  },
	computed: {
    zoom() {
      return this.$store.getters['addons/zoom/zoom'];
    },
  },
  data() {
    return {
      items: [
				{id: 0.25, name: '25%'},
				{id: 0.50, name: '50%'},
				{id: 0.80, name: '80%'},
				{id: 1, name: '100%'},
				{id: 1.25, name: '125%'},
				{id: 1.5, name: '150%'},
				{id: 2, name: '200%'},
			],
			count: 3,
      dialog : false,
    }
  },
	methods: {
    closeDialog() {
      this.$store.dispatch('modals/close', 'editImage');
    },
		zoomTransform(level) {
			// it is setting for 100% 
      if(level <= -1 || level >= 7) return;

      this.count = level;

      const zoom = this.items[level];

      this.setZoom(zoom.id)
    },
		zoomChange(level) {
      this.count = this.items.findIndex((item) => item.id === level.transform);

      this.setZoom(level.transform)
    },
		setZoom(transform) {
      this.$store.dispatch("addons/zoom/SET_ZOOM_LIENZO", transform);
    },
	}
};
</script>

<style>
</style>