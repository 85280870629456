//*MODULES
import HeaderModule from "../../components/modules/header/HeaderModule.vue";
import SidebarModule from "../../components/modules/sidebar/SidebarModule.vue";
import PreviewModule from "@/components/modules/preview/PreviewModule.vue";
import ModalUpload from "../03-Uploads/ModalUpload.vue";
import EditImage from "@/views/02-EditImage/EditImage.vue";
//*COMPONENTS COMMON
import {
  ConfirmationMessage,
  generateKeywords,
  HtmlToImage,
  ScreensizeModal,
  IscModalAlert,
  AlertSetting,
  SignedUrlUploadMixin,
  PicUploaderMixin
} from "@design/styleguide";
import QuestionModule from "@/components/modules/question/QuestionModule.vue";
import CpDropzone from "@/components/dropzone/CpDropzone.vue";
import CpBtnCommon from "@/components/buttons/CpBtnCommon.vue";
//import ImageCropperModal from "../../../components/cropper/ImageCropperModal.vue";

const idClientStorage = sessionStorage.getItem("client_id");
const clientId = Number(idClientStorage || process.env.VUE_APP_ID);
export default {
  name: "CreateAddon",
  components: {
    HeaderModule,
    SidebarModule,
    ConfirmationMessage,
    QuestionModule,
    PreviewModule,
    CpDropzone,
    CpBtnCommon,
    ModalUpload,
    EditImage,
    ScreensizeModal,
    IscModalAlert,
    //ImageCropperModal,
  },
  mixins: [ AlertSetting, SignedUrlUploadMixin, PicUploaderMixin ],
  props: {
    object: {
      type: String,
    },
  },
  data: () => ({
    idClient: clientId,
    output: null,
    typeQuestion: 0,
    dialog: false,
    editAddon: true,
    showEdit: true,
    notifications: false,
    sound: true,
    widgets: false,
    item: "100%",
    imgTrans: "",
    heightDi: 0,
    widthDi: 0,
    select: 1,
    textquestion: "",
    question_select_index: -1,
    hex: "#FFFFFF",
    uploadModal: false,
    linkText: "",
    saveLoading: false,
    sentMessage: "",
    showMessage: false,
    itemsModal: [
      { id: 1, tab: "ISC Post" },
      { id: 2, tab: "Upload Image" },
      { id: 3, tab: "Stock Images" },
    ],
    uploadingPic: false,
    idsQuestionsDb: [],
    resetRefFile: false,
    isScreensizeLess: false,
    /* progress: {
			progress: 100
		} */
  }),
  created() {
    this.$store.dispatch("addons/create/FIND_QUESTIONS");
    this.getDimensions();
    window.addEventListener("resize", this.getDimensions);    
  },
  computed: {
    element() {
      return this.$store.getters["addons/create/addon"];
    },
    elementsaved() {
      return this.$store.getters["addons/create/addonsaved"];
    },
    items() {
      return this.$store.getters["addons/create/listQuestions"];
    },
    message() {
      return this.$store.getters["addons/create/message"];
    },
    progress() {
      const files = this.$store.getters["post/uploads/files"];
      if (files.length > 0) return files[0];
      return files;
    },
    isHiddenButton(){
      return this.$store.getters["addons/saved/hiddenSaveButton"];
    },
    errorMsg(){
      return this.$store.getters["global/util/getErrMsgGraphQl"];
    }
  },
  methods: {
    resetValue() {
      this.resetRefFile = false;
    },
    open() {
      this.uploadModal = true;
      this.$store.dispatch("modals/open", "uploads");
    },
    questionType(type) {
      this.typeQuestion = type;
    },
    /* SAVE ADDON */
    folderIdAddon(id, setIdAddon) {
      if (id <= 0) return;
      this.saveAddon(id, setIdAddon);
    },
    triggerSaveAddon(val) {
      if (val) this.editProjectAddon(this.element.folderId);
    },
    async saveAddon(idFolder, setIdAddon) {
      // validation name addon
      const isName = this.validateName();
      const isClientId = this.validateClientId(clientId);
      if (!isName) return;
      if (!isClientId) return;
      try {
        this.$store.commit("global/saved/SET_LOADING", {
          load: true,
          status: false,
        });
        const finalImage = await this.getUrlFinalImage();
        const questions = this.refactorQuestions();
        const addon = this.dataAddon(idFolder, questions, finalImage, clientId);
        //* setting this function to catch errors GraphQl
        this.$store.dispatch("addons/saved/CREATE", { addon, setIdAddon, errorFunc: this.setErrorGraphQl });
      } catch (error) {
        console.log("[Error] in function saveAddon ", error);
        this.$store.commit("global/saved/SET_LOADING", {
          load: false,
          status: false,
        });
        this.invalidateMessage("Something went wrong!");
      }
    },
    setErrorGraphQl( errorMsg){
      let modifiedErrMsg = errorMsg.replace("GraphQL error:", "")
      this.errorModal(modifiedErrMsg);
      
    },
    async editProjectAddon(idFolder) {
      const isName = this.validateName();
      const isClientId = this.validateClientId(clientId);
      if (!isName) return;
      if (!isClientId) return;
      try {
        this.$store.commit("global/saved/SET_LOADING", {
          load: true,
          status: false,
        });
        const finalImage = await this.getUrlFinalImage();
        if (!finalImage) return;
        // Actions Questions
        await this.deleteQuestions();
        await this.updateQuestions();
        await this.createQuestions();
        const addon = this.dataAddon(idFolder, [], finalImage, clientId);
        delete addon.questions;
        this.$store.dispatch("addons/saved/UPDATE", {
          id: this.element.addonId,
          addon,
          errorFunc: this.setErrorGraphQl 
        });
      } catch (error) {
        console.log("[Error] in function editProjectAddon ", error);
       
        this.$store.commit("global/saved/SET_LOADING", {
          load: false,
          status: false,
        });
        this.invalidateMessage("Something went wrong!");
      }
    },
    dataAddon(idFolder, questions, finalImage, clientId) {
      const data = {
        clientId,
        name: this.element.name,
        _position: 3,
        folderId: idFolder,
        dataObjects: {
          image: {
            id: this.element.id,
            header: this.element.header,
            mimeType: this.element.mimeType,
            height: this.element.height,
            width: this.element.width,
            path: this.element.path,
            thumbnails: this.element.thumbnails,
          },
          cropped: this.element.cropped,
          transform: this.element.transform,
          x: this.element.x,
          y: this.element.y,
          buttonMessage: !this.element.message
            ? this.message.button
            : this.element.message.button,
          logoPreview: !this.element.message
            ? this.message.logoPreview
            : this.element.message.logoPreview,
        },
        description: !this.element.message
          ? ""
          : this.element.message.description,
        finalImage,
        logo: !this.element.message
          ? ""
          : !this.element.message.logo
          ? ""
          : this.element.message.logo,
        message: !this.element.message ? "" : this.element.message.confirmation,
        title: !this.element.message ? "" : this.element.message.title,
        typeUser: "user",
        styles: !this.element.design ? {} : this.element.design,
        questions,
      };
      return data;
    },
    refactorQuestions() {
      const newQuestions = [];
      if (this.element.question.length > 0) {
        this.element.question.forEach((el) => {
          newQuestions.push({
            question: el.type,
            textQuestion: el.question,
            required: el.required,
            position: el.position,
            options: el,
          });
        });
        // remove some attributes
        newQuestions.forEach((el) => {
          delete el.options.type;
          delete el.options.question;
          delete el.options.required;
          delete el.options.idQuestionDb;
          delete el.options.position;
          delete el.options.id;
        });
        return newQuestions;
      }
      return newQuestions;
    },
    async getUrlFinalImage() {
      const capture = this.$refs.captureQuestionImage;
      const file = await this.convertToJpegAndFile(capture);
      return await this.getUploadedPicture(file); // it comes from mixin PicUploaderMixin
    },
    //*QUESTIONS
    addEmitId(id) {
      this.idsQuestionsDb.push(id);
    },
    async deleteQuestions() {
      if (this.idsQuestionsDb.length > 0) {
        for (const id of this.idsQuestionsDb) {
          await this.$store.dispatch("addons/question/DELETE_QUESTION", id);
        }
      }
    },
    async updateQuestions() {
      if (this.element.question.length <= 0) return;
      for (const question of this.element.question) {
        if (question.idQuestionDb) {
          const componentQuestion = this.refactorQuestionDb(question);
          await this.$store.dispatch("addons/question/UPDATE", {
            id: question.idQuestionDb,
            componentQuestion,
          });
        }
      }
    },
    async createQuestions() {
      if (this.element.question.length <= 0) return;
      for (const question of this.element.question) {
        if (!question.idQuestionDb) {
          const componentQuestion = this.refactorQuestionDb(question);
          componentQuestion.addonId = this.element.addonId;
          await this.$store.dispatch("addons/question/CREATE", {
            componentQuestion,
          });
        }
      }
    },
    refactorQuestionDb(question) {
      const componentQuestion = {
        question: question.type,
        textQuestion: question.question,
        required: question.required,
        position: question.position,
        options: { ...question },
      };
      delete componentQuestion.options.type;
      delete componentQuestion.options.question;
      delete componentQuestion.options.required;
      delete componentQuestion.options.position;
      delete componentQuestion.options.id;
      delete componentQuestion.options.idQuestionDb;
      return componentQuestion;
    },
    itemData(val) {
      this.item = val;
    },
    indexSelected(index) {
      this.question_select_index = index;
    },
    setImageBack() {
      let image = "https://cdn.vuetifyjs.com/images/cards/cooking.png";
      this.$store.commit("addons/create/SET_BACKGROUND_ADDON", image);
    },
    addonEdit(val) {
      this.editAddon = val;
    },
    saveImage() {
      this.$store.commit("addons/create/SET_SAVED_IMAGE", this.element);
    },
    updateRequired() {
      this.$store.commit("addons/create/SET_QUESTIONS", this.element.question);
    },
    addItem(id) {
      if (id == 1) this.createItemQuestion(id);
    },
    linkSent(val) {
      this.linkText = val;
    },
    async savingFile(file) {
      // validation client
      const isClientId = this.validateClientId(clientId);
      if (!isClientId) return;
      try {
        if (!file || file.type.indexOf("image/") !== 0) return;
				const fileSizeKb = file.size * 0.001; 
        const files = [file];
        this.$store.dispatch("post/uploads/FILES_PROGRESS", { files });
        const keywords = generateKeywords(file.name);
        this.uploadingPic = true;
        const index = 0;

        //* signed Url S3 and upload too. (Mixin File) 
        const { fileName, contentType } = await this.setUrlSignedS3AndUpload(
          file,
          index,
          clientId,
          fileSizeKb
        );
        // get Object from S3 and process for all thumbnails. (Mixin File)
        const respProcessThumbnails = await this.getProcessThumbnailsImage({
          fileKey: fileName,
          contentType,
        });
        //! Do not remove
        /* const resp = await this.uploadFile(file, 0, clientId, fileSizeKb);
        if (!resp) return; */
        
        // reset progress image upload
        this.$store.dispatch("post/uploads/RESET_FILES", { index });
        const {
          data: {
            file: { full, thumbnails },
          },
        } = respProcessThumbnails;

        const upload = {
          path: full.path,
          mimeType: file.type,
          width: full.width,
          height: full.height,
          thumbnails,
					sizeKb: fileSizeKb
        };
        const object = {
          clientId,
          typeUser: "user",
          keywords,
        };
        const respUpload = await this.$store.dispatch("post/uploads/CREATE", {
          upload,
          object,
        });
        if (!respUpload) {
          this.resetItems();
          return;
        }
        this.uploadingPic = false;
        this.findUploads();
        this.setBackGroundImage(respUpload);
      } catch (error) {
        console.error("[Error] in function savingFile: ", error);
        this.resetRefFile = true;
        this.resetItems();
        let msgError = this.progress?.msgError ?? "Server fails to upload a file, please try it later."
        this.errorModal(msgError);
      }
    },
    resetItems() {
      setTimeout(() => {
        this.$store.dispatch("post/uploads/RESET_FILES", { index: 0 });
      }, 4000);
      this.uploadingPic = false;
    },
    async uploadFile(file, index, clientId, fileSizeKb) {
      let formData = new FormData();
      formData.append("file", file);
      const { data } = await this.$store.dispatch("post/uploads/UPLOAD_FILE", {
        formData,
        index,
        clientId, 
        fileSizeKb
      });
      return data;
    },
    confirmation(val) {
      this.sentMessage = val;
    },
    messageShow(val) {
      this.showMessage = val;
    },
    validateName() {
      if (!this.element.name) {
        this.invalidateMessage("It is required Addon's name");
        return false;
      } else return true;
    },
    validateClientId(id) {
      if (!id) {
        this.invalidateMessage("Client does not exist.");
        return false;
      } else return true;
    },
    //* Modals response 
    invalidateMessage(text) {
      this.errorModal(text);
    },
    successMessage(text){
      this.successModal(text);
    },
    findUploads() {
      this.$store.dispatch("post/uploads/FIND", { clientId, type:'image' });
    },
    setBackGroundImage(item) {
      if (!item) return;
      const {
        data: { upload },
      } = item;
      this.$store.dispatch("addons/create/ADD_BACKGROUND_IN_ADDON", upload);
    },
    getDimensions() {
      this.width = document.documentElement.clientWidth;
      this.width < 1024
        ? (this.isScreensizeLess = true)
        : (this.isScreensizeLess = false);
    },

    async convertToJpegAndFile(capture) {
      const dataUrl = await HtmlToImage.toBlob(capture, {
        quality: 0.95,
      });
      const file = new File([dataUrl], "photo_finalImage_addon.png", {
        type: "image/png",
        lastModified: new Date().getTime(),
      });
      return file;
    },
  //! Old function, please do not remove it.  
    // async uploadImage(file) {
    //   let formData = new FormData();
    //   formData.append("file", file);
    //   const {
    //     data: {
    //       file: { path },
    //     },
    //   } = await this.$store.dispatch("post/uploads/UPLOAD_SINGLE_IMAGE", {
    //     formData,
    //   });
    //   return path;
    // },
  },
  destroyed() {
    // Reset Addon
    this.$store.commit("addons/create/RESET_ADDON");
    this.$store.dispatch("global/saved/ENABLE_EDIT_PROJECT", false);
  },
  mounted() {
    // this is for recognize first all variables of uploads graphQl
    this.findUploads();
    if (this.element.addonId) {
      this.sentMessage = this.element.message.confirmation;
    }
  },
};
