<template>
  <v-app>
    <v-main>
      <router-view></router-view>
      <no-auth-modal />
    </v-main>
  </v-app>
</template>
<script>
import { NoAuthModal } from "@design/styleguide";
export default {
  components: {
    NoAuthModal,
  },
}
</script>
<style scoped>
/* #addon {
  margin-top: 60px;
} */
</style>
