<template>
  <v-btn
    :class="css"
    :block="block"
    :disabled="disabled"
    :style="[disabled && { backgroundColor: '#FFFFFF !important' }]"
    color="#FFFFFF"
    @click="callback($event)"
  >
    <v-progress-circular
      v-if="loading"
      indeterminate
      color="white"
      :size="25"
    ></v-progress-circular>
    <element v-else>
      <p class="name-button">
        {{ name }}
      </p>
      <v-icon v-if="showIcon" :size="sizeIcon" dense>
        {{ iconName }}
      </v-icon>
    </element>
  </v-btn>
</template>

<script>
export default {
  name: "CpBtnWhite",
  props: {
    name: String,
    css: {
      type: String,
      default: "white-button-ds",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    block: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    showIcon: {
      type: Boolean,
      default: false,
    },
    iconName: {
      type: String,
      default: "",
    },
    sizeIcon: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    callback(e) {
      this.$emit("click", e);
    },
  },
};
</script>

<style scoped lang="scss">
.white-button-ds {
  color: #5d6b88 !important;
  box-shadow: none !important;
  border-radius: 3px;
  font-weight: bold;
  padding: 9px 20px !important;
  text-transform: none;
  font-style: normal;
  font-size: 12px;
  line-height: 15px;
  border: 1px solid #c5d0e4 !important;
  box-sizing: border-box;
  &:hover{
    border-color: #00a5ff !important;
    &:before{
      background-color: white !important;
    }
  }
}
.white-button-ds > span > div {
  font-size: 12px;
}
.name-button {
  display: inline;
  line-height: 16px;
}
</style>
