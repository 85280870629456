<template>
  <v-row @click="setSelect(questionItem.id, questionIndex)">
    <div class="wrap__question__module">
      <div class="wrap__question__module__drag">
        <v-row style="padding: 10px" justify="end"
          ><v-icon small color="#5D6B88" style="cursor: pointer"
            >mdi-cursor-move</v-icon
          >
        </v-row>
        <v-row justify="end" style="padding: 10px">
          <v-icon
            small
            color="#5D6B88"
            style="cursor: pointer"
            @click="deleteQuestion(questionItem.id)"
            >mdi-trash-can-outline
          </v-icon></v-row
        >
      </div>
      <div class="col box-question-container ignore-elements px-9">
        <v-container fluid>
          <v-row>
            <v-col
              cols="12"
              style="padding: 0px; justify-content: center; align-items: center"
              class=""
            >
              <v-row>
                <header-question
                  questionName="What would you like to ask?"
                ></header-question>
              </v-row>
              <v-row class="input-container">
                <v-text-field
                  placeholder="Your question text goes here"
                  solo
                  hide-details
                  class="input-question"
                  v-model="questionItem.question"
                  @keyup="setQuestionByItem(questionItem.id, questionItem.question)"
                ></v-text-field>
              </v-row>
              <v-row
                class="answers-container align-start"
              >
                <v-col
                  cols="12"
                  style="
                    
                  "
                  class="px-0 question-answers-container"
                >
                  <v-text-field
                    type="text"
                    class="question-answer align-self-center"
                    v-model="questionItem.answer"
                    style="width: 100%"
                    placeholder="Your answer text goes here."
                    outlined
                    dense
                    hide-details
                    readonly
                    @keyup="setAnswerByItem(questionItem.id, questionItem.answer)"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
  </v-row>
</template>
<script>
import HeaderQuestion from "./HeaderQuestion.vue";
export default {
  name: "AnswerText",
  components: {
    HeaderQuestion,
  },
  props: {
    element: {
      type: Object,
      default: () => {
        return {};
      },
    },

    questionItem: {
      type: Object,
      required: true,
    },
    questionIndex: {
      type: Number,
      required: true,
    },
    questionSelected: {
      type: Number,
      required: true,
    },
    numberQuestion: {
      type: Number,
      default: 0,
    },
    items: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  methods: {
    setSelect(id, index) {
      this.$emit("set-select", id, index);
    },
    deleteQuestion(id_question) {
      this.$emit("delete-item", id_question);
    },
    setQuestionByItem(index, text) {
      this.$emit("set-question-by-item", index, text);
    },
    setAnswerByItem(id_question, x) {
      this.$emit("set-answer-by-item", id_question, x);
    },
  },
};
</script>

<style lang="scss" scoped>
.box-question-container {
  border: 1px solid #c5d0e4;
}

.input-container {
  .input-question {
    border: 1px solid #c5d0e4;
    border-radius: 3px;
    ::v-deep .v-input__control {
      min-height: 35px;
      max-height: 35px;
      font-size: 12px;
      line-height: 16px;
      border-radius: 3px;
      .v-input__slot {
        box-shadow: inset 0px 2px 5px rgba(197, 208, 228, 0.62) !important;
        // box-shadow: none;
        input {
          &::placeholder {
            color: #5d6b88;
          }
        }
      }
    }
  }
}
.question-answers-container {
  background-color: #ffff;
  margin-top: 0.5rem;
  padding: 5px;
  padding-right: 12px;
  max-height: 35px;
  min-height: 35px;
  .question-answer {
    box-shadow: inset 0px 2px 5px rgba(197, 208, 228, 0.62);
    border-radius: 3px;
    ::v-deep .v-input__control {
      min-height: 35px;
      max-height: 35px;
      font-size: 12px;
      line-height: 16px;
      color: #5d6b88;
      .v-input__slot {
        background-color: #f3f4f7 !important;
        box-shadow: none;
        input {
          &::placeholder {
            color: #A2ACC4;
          }
        }
        fieldset {
          border-color: transparent !important;
        }
      }
    }
  }
}
.answers-container{
  min-height: 83px;
}
</style>
