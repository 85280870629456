<template>
  <div>
    <v-row class="nav__header">
      <v-col class="nav__button" cols="6">
        <cp-btn-common @click="redirectStart" name="Start from scratch" />
      </v-col>
      <v-col class="nav__search" cols="6">
        <v-text-field
          append-icon="mdi-magnify"
          height="35px"
          background-color="white"
          hide-details
          outlined
          filled
          dense
          placeholder="Search"
          class="search-input addon-search-input"
          style="width: 156px; max-width: 156px"
          v-model="search"
        ></v-text-field>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CpBtnCommon from "@/components/buttons/CpBtnCommon";
export default {
  name: "HeaderContent",
  components: {
    CpBtnCommon,
  },
  data() {
    return {
      search: "",
    };
  },
  watch: {
    search(val) {
      this.$emit("search-text", val);
    },
  },
  methods: {
    redirectStart() {
      this.$router.push("/addon/create-addon");
    },
  },
};
</script>

<style lang="scss" scoped>
.nav {
  &__header {
    margin: 0px;
    background: #ffffff;
    min-height: 55px;
    box-shadow: 0px 2px 4px rgba(39, 52, 68, 0.1);
    position: relative;
  }
  &__search {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 11px 18px 9px 11px;
  }
}
.addon-search-input.v-input{
  // border: thin solid #C5D0E4;
  border-radius: 3px !important;
  width: 156px;
}
.addon-search-input{
  ::v-deep .v-input__control
  > .v-input__slot{
      box-shadow: inset 0px 2px 5px rgba(197, 208, 228, 0.62) !important;  
  }
}
.addon-search-input{
  ::v-deep .v-input__append-inner{
    .v-input__icon i{
        font-size: 20px;
        color: #5D6B88 !important;  
    }
  } 
}
  .addon-search-input{
    ::v-deep .v-input__control
    > .v-input__slot
    > .v-text-field__slot
    > input {
    font-size: 12px !important;
    font-weight: 400 !important;
    line-height: 10px;
    &::placeholder{
        color: #5D6B88;
        font-size: 12px !important;
        font-weight: 400 !important;
      }
    }
  }
</style>
