<template>
  <v-dialog v-model="show" style="overflow-y: auto" max-width="1200">
    <v-card>
      <v-toolbar 
        dark 
        color="white"
        elevation="1"
      >
        <v-toolbar-title style="color: black; font-weight: 700; font-size: 15px"
          >
          <h4 class="title-menu text-white">Edit image</h4>


          <cp-btn-close @click="close" :color="'black'" style="margin-top: 12px;" />
        </v-toolbar-title>
        <!-- <v-spacer></v-spacer> -->
      </v-toolbar>
      <v-row style="margin: 0px; padding: 0">
        <v-col cols="2" style="border-right: 1px solid #c5d0e499; padding: 0">
          <EditImageSidebar @optionSelected="getOptionSelected"/>
        </v-col>
        <v-col cols="10">
          <EditImageTools :selectedItemSidebar="selectedItemSidebar"/>
          <v-row>
            <v-col class="grey lighten-3">
                <v-row
									style="overflow:hidden;"
								>
                  <v-col
                    cols="12"
                    class="d-flex align-center justify-center edit-image__content"
										:style="{transform: `scale(${zoomLienzo.transform})`}"
                  >
                  <EditImageContent 
                    :selectedItemSidebar="selectedItemSidebar"
                  />
                  </v-col>
                </v-row>
                <EditImageFooter />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import CpBtnClose from "@/components/buttons/CpBtnClose";
import EditImageSidebar from "@/components/editImage/EditImageSidebar"
import EditImageTools from "@/components/editImage/EditImageTools"
import EditImageFooter from "@/components/editImage/EditImageFooter"
import EditImageContent from "@/components/editImage/EditImageContent"

export default {
  components: {
    CpBtnClose,
    EditImageSidebar,
    EditImageTools,
    EditImageFooter,
    EditImageContent
  },
  data() {
    return {
      dialog2: false,
      select: 1,
      tool1: true,
      tool3: false,
      selectedItemSidebar: 'resize',
      cropFlag: false,
      cropEdit: false,
      flipVert: false,
      flipHori: false,
      styleCustom: "",
      item: "100%",
      
      /* zoomItems: [
				{id: 0.25, name: '25%'},
				{id: 0.50, name: '50%'},
				{id: 0.80, name: '80%'},
				{id: 1, name: '100%'},
				{id: 1.25, name: '125%'},
				{id: 1.5, name: '150%'},
				{id: 2, name: '200%'},
			], */
      items: [
        { id: 1, title: "Checkboxes", icon: "mdi-format-list-checks " },
        { id: 2, title: "Radio buttons", icon: "mdi-format-list-checks " },
        { id: 3, title: "Range", icon: "mdi-battery-low" },
        { id: 4, title: "Open text", icon: "mdi-layers-triple " },
        { id: 5, title: "QR CODE", icon: "mdi-qrcode" },
        {
          id: 6,
          title: "Social network (iframe)",
          icon: "mdi-thumb-up-outline ",
        },
        { id: 7, title: "Date", icon: "mdi-calendar-month" },
      ],
    };
  },
  props: {
		element:{
			type: Object,
			default: () => {
				return {}
			}
		}, 
		addonLoading:{
			type: Boolean,
			default: false
		}
	},
  computed: {
    show: {
      get() {
        return this.$store.getters["modals/all"].find((e) => e === "editImage");
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    elementsaved() {
      return this.$store.getters["addons/create/addonsaved"];
    },
		zoomLienzo() {
      return this.$store.getters['addons/zoom/zoom'];
    },
  },
  watch: {
		element(val){
			console.log(' element object ', val);
		}
	},
  methods: {
    close() {
      this.$store.dispatch('modals/close', 'editImage');
			this.$emit('cerrar');
			this.image = null;
    },
    getOptionSelected(selectedItem) {
      this.selectedItemSidebar = selectedItem.name;
    },
    zoom(level) {
      let percent = this.item;
      let element = document.getElementById("list");
      let searchIndex = this.items.findIndex((item) => item == percent);
      let valPercent = parseInt(
        this.zoomItems[
          level == 1 ? parseInt(searchIndex) + 1 : parseInt(searchIndex) - 1
        ]
      );
      let newPercent = valPercent + "%";
      if (this.zoomItems.includes(newPercent)) {
        if (valPercent > 100) {
          this.styleCustom = "overflow: scroll";
        } else {
          this.styleCustom = "overflow: hidden";
        }
        let valScale = parseFloat(valPercent / 100);
        element.style.transition = "transform 200ms ease-in-out 0s";
        element.style.transform = "scale(" + valScale + ") translate(0px, 0px)";
        this.item = newPercent;
      } else {
        this.item = this.zoomItems[searchIndex];
      }
      console.log("item ", this.item);
      this.$emit("item", this.item);
    },
    zoomChange() {
      let percent = this.item;
      let element = document.getElementById("list");
      let valPercent = parseInt(percent.substr(0, percent.length - 1));
      if (valPercent > 100) {
        this.styleCustom = "overflow: scroll";
      } else {
        this.styleCustom = "overflow: hidden";
      }
      let valScale = parseFloat(valPercent / 100);
      element.style.transition = "transform 200ms ease-in-out 0s";
      element.style.transform = "scale(" + valScale + ") translate(0px, 0px)";
    },
    
    changeSize(dimensiones) {
      //console.log("", dimensiones);
      let w = parseFloat(dimensiones.width);
      let h = parseFloat(dimensiones.height);
      this.$store.commit("addons/create/SET_WIDTH", w);
      this.$store.commit("addons/create/SET_HEIGHT", h);
    },
    
    activateEv(el1, el2) {
      console.log("elemnest ", el1, el2);
    },
    deactivateEv(el) {
      console.log(" element ", el);
    },
    changePosition(event, el) {
      console.log("event ", event);
      console.log("element", el);
    },
  },
};
</script>

<style lang="scss">
.v-application .elevation-1 {
  box-shadow: 0px 2px 6px 2px rgba(44, 56, 82, 0.1) !important;
}

.edit-image {
  &__content {
    height: 450px;
  }
}
</style>