import CpBtnClose from "@/components/buttons/CpBtnClose";
import AddonDropzone from "@/components/dropzone/UploadDropzone";
import CpCardUpload from "@/components/card/CpCardUpload";
import CpCardListPost from "@/components/card/CpCardListPost";
import CpMasonryImages from "@/components/modalUploads/CpMasonryImages.vue";
import { 
	generateKeywords, 
	//transformThumbnails, 
	CpProgressLinear,
	IscModal,
	HandleRespMixin,
  IscModalAlert,
  SignedUrlUploadMixin
} from "@design/styleguide";
export default {
  name: "ModalUpload",
  components: {
		CpBtnClose,
		AddonDropzone,
		CpCardUpload,
		CpProgressLinear,
		CpCardListPost,
		CpMasonryImages,
		IscModal,
		IscModalAlert
	},
	mixins:[ HandleRespMixin, SignedUrlUploadMixin ],
	props: {
		selectedLogo: {
			type: Boolean,
			default: false
		},
		items:{
			type: Array,
			return: () => {
				return []
			}
		}
	},
  computed: {
    show: {
      get() {
        return this.$store.getters['modals/all'].find(e => e === 'uploads');
      },
      set(val) {
        this.$emit('input', val);
      },
    },
		images(){
			return this.$store.getters['post/images/images'];
		},
		imagesByKeywords(){
			return this.$store.getters['post/images/imagesByKeywords'];
		},
		uploadsImages(){
			return this.$store.getters['post/uploads/uploads'];
		},
		uploadsByKeywords(){
			return this.$store.getters['post/uploads/uploadsByKeywords'];
		},
		filesProgress(){
			return this.$store.getters['post/uploads/files'];
		},
		latestPosts(){
			return this.$store.getters['home/post/latesPosts'];
		},
		folders(){
			return this.$store.getters['home/post/foldersWithPosts'];
		},
		posts(){
			return this.$store.getters['post/uploads/posts'];
		},
		postsByNames(){
			return this.$store.getters['post/templates/postsUser'];
		}
  },
  data: () => ({
    tab: null,
		search: '',
		image: null,
		take: 15,
		page: 1,
		addImages: [],
		loadingScroll: false,
		firstAmountOfImages: 0,
		tabSelected: '',
		showStockImages: true,
		showLatestFoldersPost: true,
		showUploadImages:true,
		showInputSearch: true,
		sentImage:{
				height:'',
				width:''
			}, 
		stockImageActived: null,
		takeFolder: 2,
    pageFolder: 1,
    limitPost: 5,
		firstAmountOfFolders: 0,
		firstAmountOfUploads: 0,
		addFolders:[],
		addUploads:[],
		takeUpload: 15,
		pageUpload: 1,
		oldId: 0,
		amountItems: 0,
		pagePost: 1,
		postLatestId: 0,
		postsFolderId: 0, 
		postId:0,
		resetRefFiles: false,
		//** var delete image */
		loadingBtn: false,
		deleteItemModal: false,
		selectedIdFile: 0
  }),
	watch: {
		tab(selectedTab){
			switch (selectedTab) {
				case 0:
					if(this.selectedLogo) this.settingsUploadImage();
					else {
						this.selectedTab = 'iscPost';
						this.showInputSearch = true;
					}
				break;	
				case 1:
					if(this.selectedLogo) this.settingsStockImages();
					else this.settingsUploadImage();
					break;
				case 2:
					if(!this.selectedLogo) this.settingsStockImages();
					break;
				default:
					break;
			}
		},
		images(images){
			if(images.length > 0){
				this.activeScroll = true;
				images.forEach(el  => {
					this.addImages.push(el);
				});
			}
		},
		folders(folders){
			if(folders.data.length > 0){
				folders.data.forEach(el  => {
					this.addFolders.push(el);
				});
			}
		},
		posts(newPosts){
			const { items } = this.addFolders.find((el) => el.id === this.oldId);
			let isId = false;
			for(let i = 0; i < newPosts.length && !isId; i++ ){
				isId = items.some( el => {return el.id === newPosts[0].id });
			}
			if(!isId){
				this.addFolders.forEach((el) =>{
					if(el.id === this.oldId){
						el.items = [
							...el.items,
							...newPosts
						]
					}
				});
			}
		},
		uploadsImages(items){
			if(items.length > 0) {
				items.forEach(el  => {
					this.addUploads.push(el);
				});
			}
		}, 
		search(val){
			switch (this.selectedTab) {
				case 'stockImages':
					this.switchView(val)
					break;
				case 'iscPost':
					this.switchView(val)
					break;
				case 'iscUploadImage':
					this.switchView(val)
					break;
				default:
					break;
			}
		},
	},
	mounted(){
		let self = this
    window.addEventListener('keyup', function (event) {
      if (event.keyCode === 13) {
				if(self.selectedTab === 'stockImages'){
					self.showStockImages = false;
					self.findByKeywords('images', self.search);
				} else if(self.selectedTab === 'iscUploadImage'){
					self.showUploadImages = false;
					self.findUploadsImagesByKeywords('user', self.search, 'image')
				}else {
					self.showLatestFoldersPost = false;
					self.findPostsByType('user', self.search)
				}
      }
    });
		this.findImages();
		this.findUploads();
		this.getLatestPosts();
		this.findFoldersWithPosts();
	},	
  methods: {
		resetValue(){
			this.resetRefFiles = false; 
		},
    close() {
      this.$store.dispatch('modals/close', 'uploads');
			this.$emit('cerrar');
			this.image = null;
    },
		settingsUploadImage(){
			this.selectedTab = 'iscUploadImage';
			this.showInputSearch = true;
		},
		settingsStockImages(){
			this.selectedTab = 'stockImages';
			this.showInputSearch = true;
		},
		// Calling Stores
		findImages(){
			this.$store.dispatch('post/images/FIND', 
			{ take: this.take,  page: this.page });
		},
		findUploads(){
			this.$store.dispatch('post/uploads/FIND', { take: this.takeUpload, page: this.pageUpload, clientId: this.clientId,type:'image' });
		},
		getLatestPosts(){
			this.$store.dispatch('home/post/FIND_LATEST_POSTS_BY_TYPE', {take: 5, typeUser: 'user', clientId: this.clientId});
		},
		findFoldersWithPosts(){
			this.$store.dispatch('home/post/LIST_POSTS_WITH_FOLDER', {
				take: this.takeFolder,
				page: this.pageFolder,
				limitPost: 6,
				clientId: this.clientId
			});
		},
		urlThumbnail(images){
      return images.find((item) => item.quality === 'SCREEN_4').location;
    }, 
		// wait to add quality attribute
		setHW(image) {
      const imageResize = image.thumbnails.find((item) => item.quality === 'SCREEN_3');
      let width;
      let height;
      if(imageResize.width >= 533 && imageResize.height >= 800) {
        width = 420.267
        height = 630.4
      } else if (imageResize.width >= 640 && imageResize.height >= 800) {
        width = 504
        height = 630
      } else if (imageResize.width >= 800 && imageResize.height >= 532) {
        width = 782
        height = 522
      } else {
        width = imageResize.width
        height = imageResize.height
      }
      return { width, height }
    },
		startClick(image) {
      // const { width, height } = this.setHW(image);
			// console.log('width ', width);
			// console.log('height ', height);
			//console.log('image ', image );
			this.stockImageActived = image.id;
			if(this.selectedLogo){
				const { header:{ keywords }} = image;
				this.image = image.thumbnails.find((item) => item.quality === 'SCREEN_4');
				this.image.name = keywords[0];
			} else {
				this.image = image;
				// reset selected Image square
				this.postLatestId = 0;
				this.postsFolderId = 0;
				this.postId = 0;
			}
    },
		startPostLatest(item){
			this.postsFolderId = 0;
			this.postId = 0;
			this.stockImageActived = 0;
			this.postLatestId = item.id;
			this.refactorImage(item);
		},
		startPostFolderImage(item){
			this.stockImageActived = 0;
			this.postLatestId = 0;
			this.postId = 0;
			this.postsFolderId = item.id;
			this.refactorImage(item);
		},
		startListPost(item){
			this.postLatestId = 0;
			this.postsFolderId = 0;
			this.stockImageActived = 0;
			this.postId = item.id;
			this.refactorImage(item);
		},
		refactorImage(item){
			this.image = {
				width: item.width,
				height: item.height,
				path: item.finalImage,
				thumbnails: [
					{
						location: item.finalImage,
						quality: 'SCREEN_4',
						width: item.width,
						height: item.height,
					}
				]
			}
		},
		chosenItem(){
			if(this.selectedLogo) this.$emit('chosen-item', this.image);
			else {
				
				this.$store.dispatch('addons/create/ADD_BACKGROUND_IN_ADDON', this.image);
				this.$store.dispatch('addons/changes/SAVE_CHANGES');

			}
			this.close();	
		},
		// Handle Scroll and charging more images and folders
		handleScroll() {
			/* 
				scrollHeight: all height scroll
				scrollTop: height location where scroll is marked. 
				style.height: height of view where scroll works.
			*/
			const { scrollHeight,  scrollTop, clientHeight } = this.$refs.container;
			const entireHeight = Math.round(scrollTop + clientHeight);
			this.loadingScroll = false;
			if((entireHeight >= scrollHeight) && !this.loadingScroll){
				const validationLengthImages = this.validationMoreImages();
				if(!validationLengthImages) return;
				this.addItem();
				this.loadingScroll = true;
			} 
		},
		handleScrollUpload() {
			/* 
				scrollHeight: all height scroll
				scrollTop: height location where scroll is marked. 
				style.height: height of view where scroll works.
			*/
			const { scrollHeight,  scrollTop, clientHeight } = this.$refs.containerUpload;
			const entireHeight = Math.round(scrollTop + clientHeight);
		
			if((entireHeight >= scrollHeight)){
				const validationLengthUploads = this.validationMoreUploads();
				if(!validationLengthUploads) return;
				//console.log('more Items');
				this.addMoreUploads();
			} 
		},
		handleScrollFolders() {
			const { scrollHeight,  scrollTop, clientHeight } = this.$refs.post;
			const entireHeight = Math.round(scrollTop + clientHeight);
			this.loadingScrollFolder = false;
			if((entireHeight >= scrollHeight) && !this.loadingScrollFolder){
				const validationLengthFolders = this.validationMoreFolders();
				if(!validationLengthFolders) return;
				this.addFolder();
				this.loadingScrollFolder = true;
			} 
		},
		addItem() {
			this.page++;
			this.$store.dispatch('post/images/FIND', 
				{ take: this.take,  page: this.page }
			);
		},
		addFolder() {
			this.pageFolder++;
			this.findFoldersWithPosts();
		},
		addMoreUploads() {
			this.pageUpload++;
			this.findUploads();
		},
		validationMoreImages() {
			if(this.firstAmountOfImages === 0){
				this.firstAmountOfImages = this.addImages.length;
				return true;
			}
			if(this.firstAmountOfImages < this.addImages.length){
				this.firstAmountOfImages = this.addImages.length;
				return true;
			} else return false;
		},
		validationMoreFolders() {
			if(this.firstAmountOfFolders === 0){
				this.firstAmountOfFolders = this.addFolders.length;
				return true;
			}
			if(this.firstAmountOfFolders < this.addFolders.length){
				this.firstAmountOfFolders = this.addFolders.length;
				return true;
			} else return false;
		},
		validationMoreUploads() {
			if(this.firstAmountOfUploads === 0){
				this.firstAmountOfUploads = this.addUploads.length;
				return true;
			}
			if(this.firstAmountOfUploads < this.addUploads.length){
				this.firstAmountOfUploads = this.addUploads.length;
				return true;
			} else return false;
		},
		// Search by keywords
		switchView(valSearch){
			if(valSearch === ''){
				this.showStockImages = true;
				this.showUploadImages = true;
				this.showLatestFoldersPost = true;
			}
		},
		findByKeywords(type, text){
			let strings = text.split(",");
			let keywords = strings.map(item=>item.trim())
			this.$store.dispatch('post/'+type+'/FIND_WITH_KEYWORDS',{ keywords });
		},
		findPostsByType(type, text){
			let strings = text.split(",");
			let keywords = strings.map(item=>item.trim());
			this.$store.dispatch('post/templates/FIND_POSTS_BY_USERS',{ 
				page: 1,
				take: 30,
				keywords,
				user: type,
				clientId: this.clientId 
			});
		},
		findUploadsImagesByKeywords(typeUser, text, type){
			let strings = text.split(",");
			let keywords = strings.map(item=>item.trim());
			console.log('KEYWORDS', keywords );
			this.$store.dispatch('post/uploads/FIND_UPLOADS_BY_KEYWORDS',{ 
				page: 1,
				take: 50,
				keywords,
				typeUser,
				clientId: this.clientId,
				type 
			});
		},
		sendFiles(items){
			// validation clientId
			const isClient = this.validationClientId(this.clientId);
			if (!isClient) return;
			
			let files = [];
			for(let i = 0; i < items.length; i++ ){
				files.push(items[i])
			}
			if(files.length > 0){
				//* clean Input Search
				if(!this.showUploadImages){
					this.search = '';
					this.showUploadImages = true;
				}
				this.$store.dispatch('post/uploads/FILES_PROGRESS', { files });
				for(const [key, file] of files.entries()){
					this.saveAndUploadFile(file, key);
				}
			}
		},
		async saveAndUploadFile(file, index){
			try {
				if(!file || file.type.indexOf('image/') !== 0) return;
				const fileSizeKb = file.size * 0.001; 
				const keywords = generateKeywords(file.name);
				//this.$emit('uploading', true);
				//* signed Url S3 and upload too. (Mixin File SignedUrlUploadMixin) 
        const { fileName, contentType } = await this.setUrlSignedS3AndUpload(
          file,
          index,
          this.clientId,
          fileSizeKb
        );
        // get Object from S3 and process for all thumbnails. (Mixin File SignedUrlUploadMixin)
        const respProcessThumbnails = await this.getProcessThumbnailsImage({
          fileKey: fileName,
          contentType,
					index
        });
				//! Do not remove it.
				/* const resp = await this.uploadFile(file, index, this.clientId, fileSizeKb);
				if(!resp) return; */
				// reset progress image upload
				this.$store.dispatch('post/uploads/RESET_FILES', { index });
				const {
          data: {
            file: { full, thumbnails },
          },
        } = respProcessThumbnails;
				//const newThumbnails = transformThumbnails(thumbnails, full);
				const upload = {
					path: full.path,
					mimeType: file.type,
					width:  full.width,
					height: full.height,
					thumbnails,
					sizeKb: fileSizeKb 
				};
				const object = {
					clientId: this.clientId,
					typeUser: 'user',
					keywords
				}
        await this.$store.dispatch('post/uploads/CREATE',{
					upload,
					object
				});
        //*Reset all for uploads.
				this.resetTotalUploads();
      } catch (e) {
				this.resetRefFiles = true;
				setTimeout(() => {
					this.$store.dispatch('post/uploads/RESET_FILES', { index });
				}, 4000);
      }
		},
		//! Do note remove it.
		// async uploadFile(file, index, clientId, fileSizeKb ) {
		// 	let formData = new FormData();
		// 	//formData.append('type', type);
		// 	formData.append('file', file);
		// 	const { data } = await this.$store.dispatch('post/uploads/UPLOAD_FILE', {
		// 		formData,
		// 		index,
		// 		clientId, 
		// 		fileSizeKb
		// 	});
		// 	return data;
		// },
		//* charge data click by arrow next 
		addData(idFolder, itemsLength){
			// first validation 
			if(this.oldId !== idFolder){ 
				this.amountItems = 0; 
				this.pagePost = 1;
				this.oldId = idFolder;
			}
			if(!this.validationItems(itemsLength)) return;
			this.pagePost++;
			this.$store.dispatch('post/uploads/FIND_POSTS_BY_FOLDER',{
				page: this.pagePost,
				take: 6,
				folderId: idFolder,
				clientId: this.clientId
			}); 
		},
		validationItems(itemsLength) {
			/* console.log('vald ammount ', this.amountItems);
			console.log('vald itemsLength ', itemsLength); */
			if(this.amountItems < itemsLength){
				this.amountItems = itemsLength;
				return true;
			} else return false;
		},
		validationClientId(id){
			if (!id) {
				this.errorModal('Client does not exist');
				return false;
			} else return true;
		},
		//** DELETE IMAGE FUNC */
		deleteItemImage(id){
			this.selectedIdFile = id;
			this.deleteItemModal = true;
		},
		async isDeleted(e) {
			if(!e) return;
			this.loadingBtn = true;
			await this.$store.dispatch('post/uploads/DELETE', {
				id: this.selectedIdFile,
				responseFunc: this.responseMessageGraphql
			});
			this.deleteItemModal = false;
			this.resetTotalUploads();
			this.loadingBtn = false;
		},
		resetTotalUploads(){
			this.$store.dispatch('post/uploads/RESET_UPLOADS');
			this.addUploads = [];
			this.firstAmountOfUploads = 0;
			this.pageUpload = 1;
			this.takeUpload = 15; 
			this.findUploads();	
		},
  },
}