import Vue from "vue";
import VueRouter from "vue-router";
import Addon from "../views/Addon";
import CreateAddon from '../views/01-Create/CreateAddon.vue';
Vue.use(VueRouter);

const routes = [
  {
    path: "/addon",
    name: "Addon",
    component: Addon,
  },
  {
    path: "/addon/create-addon",
    name: "CreateAddon",
    component: CreateAddon,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
