<template>
  <form enctype="multipart/form-data">
    <div class="addon-dropzone">
      <v-sheet class="py-15">
        <div class="text-center">
          <p class="upload-title">Upload your image</p>
          <p class="upload-format">PNG. JPG. GIF files are allowed</p>
					<v-img src="https://i.ibb.co/BTVTkLp/Vector.png" height="54" contain class="upload-img-ico">
          </v-img>
          <p class="mb-0 mt-3 text-style"><b>Drag and Drop</b></p>
          <p class="text-style mt-3">or</p>
          <v-btn
						width="133"
						height="35"
						class="isc-btn-primary"
						color="primary"
						small
          >
            Choose your files
          </v-btn>
        </div>
      </v-sheet>
			<input 
				ref="file" 
				type="file" 
				class="input-file" 
				@change="sendFile"
				multiple="multiple"
        accept="image/png, image/gif, image/jpeg"
			/>
    </div>
  </form>
</template>

<script>
import { UploadFunctionsMixin} from "@design/styleguide";
export default {
  name: "UploadDropzone",
  mixins: [UploadFunctionsMixin],
  data() {
    return {
      file: null,
      message: "",
      error: false,
      uploading: false,
      progress: 0,
			uploads: [],
			statesSuccess: [],
			files: [],
			count: 0
    };
  },
  props: {
    resetFiles: {
      type: Boolean,
      default: false
    },
    max: {
      type: Number,
      default: () => {
        return 6
      }
    }
  },
  watch:{
    resetFiles(val){
      if(val) {
        this.$refs.file.value = null;
        this.$emit('reset-value');
      }
    },
  },
  methods: {
    async sendFile() {
			this.files = this.$refs.file.files;
			if(this.files.length < 0) return;
      let isValidateMaxUploads = this.validateMaxUploadsFiles(this.max, this.files);
      if(isValidateMaxUploads) return;
			this.validationImages(this.files);	
    },
		validationImages(files){
			this.count = 0;
			this.statesSuccess = [];
			this.uploads = [];
      for (const file of files) {
        this.uploads.push(file)
				let success = true
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (evt) => {
          let img = new Image();
          img.onload = () => {
            success = img.width >= 160 && img.height >= 160;
						this.validationProcess(success);
          };
          img.src = evt.target.result;
        };
      }
		},
		validationProcess(success){
			this.statesSuccess.push(success);
			if(!success){ 
				this.count++
				let msg ='Upload images larger than 160 * 160 pixels.';
        this.$emit('msg-error', msg); 
			}
			if((this.statesSuccess.length == this.files.length) && this.count == 0 ){
				this.$emit('sending-files', this.uploads);
			}
		}, 
  }
};
</script>

<style lang="scss" scoped>
.text-style{
  font-size: 12.5px;
  line-height: 16px;
  color: #C5D0E4;
}
.addon-dropzone {
  //padding: 10px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  outline: 0.5px dashed #ffffff;
  outline-offset: -10px;
  border: 0.5px dashed #00A5FF;
  box-sizing: border-box;
  border-radius: 6px;

  .input-file {
    opacity: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    cursor: pointer;
  }

  .call-to-action {
    font-size: 1.2rem;
    color: #c5d0e4;
  }
}

.upload {
  &-title {
    font-weight: 700;
    font-size: 15px;
    line-height: 16px;
    color: #2C3852;
    margin-bottom: 5px;
  }
  &-format {
    font-size: 10px;
    line-height: 16px;
    color: #96A0B8;
  }
  &-img-ico{
    opacity: .3;
  }
}
.isc-btn-primary{
  box-shadow: 0px 2px 4px rgba(44, 56, 82, 0.1) !important;
  border-radius: 3px !important;
}
</style>
