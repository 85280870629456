<template>
  <div class="masonry masonry--h">
    <figure
      v-for="(item, i) in dataPics"
      :key="i"
      :class="{
        active: item.id === imageActiveId,
      }"
      class="masonry-brick masonry-brick--h"
    >
      <v-img
        class="masonry-img"
        :src="urlThumbnail(item.thumbnails)"
        alt="item.name"
        @click="startClick(item)"
      >
      <template v-slot:placeholder>
        <v-row
          class="fill-height ma-0"
          align="center"
          justify="center"
        >
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </v-row>
      </template>
      </v-img>
      <div
        v-if="isBtnDelete"
        class="masonry-brick__content"
        @click="startClick(item)"
      >
        <v-btn
          icon
          color="white"
          class="upload__btn"
          @click="btnDeleteIdItem(item.id)"
        >
          <v-icon small>fas fa-times</v-icon>
        </v-btn>
      </div>
    </figure>
    <v-skeleton-loader
      v-if="isLoading"
      class="masonry-brick masonry-brick--h"
      type="image"
      v-for="i in 10"
      :key="i"
      tile
    ></v-skeleton-loader>
  </div>
</template>

<script>
export default {
  name: "CpMasonryImages",
  data() {
    return {};
  },
  props: {
    dataPics: {
      type: Array,
      default: [],
    },
    imageActiveId: null,
    isBtnDelete: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    isLoading: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
  },
  methods: {
    startClick(item) {
      this.$emit("click-start", item);
    },
    urlThumbnail(images) {
      return images.find((item) => item.quality === "SCREEN_4").location;
    },
    btnDeleteIdItem(id) {
      this.$emit("btn-delete-id", id);
    },
  },
};
</script>

<style lang="scss" scoped>
//* this two scss we are using to other projects.
//* Why did not those classes write to Scss global?
//* Because absolute position (Button) puts aside the image
.upload__btn {
  position: absolute;
  right: 5px;
  top: 5px;
  color: #fff;
  &:hover::before{
    display: none;
  }
}

.masonry {
  display: flex;
  width: 100%;

  &--h {
    flex-flow: row wrap;
  }

  &--v {
    flex-flow: column wrap;
    max-height: 1080px;
  }

  &--h,
  &--v {
    margin-left: -8px; /* Adjustment for the gutter */
  }

  &-brick {
    overflow: hidden;
    margin: 0 0 8px 8px; /* Some Gutter */
    color: white;
    position: relative;
    cursor: pointer;

    &:after {
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 5000;
      transform: translate(-50%, -50%);
      transition: font-size 0.25s, opacity 0.25s ease-in-out;
      font-weight: 700;
      opacity: 0.5;
      font-size: 1.25em;
    }
    &:hover &__content {
      /* 	opacity: 0.5; */
      display: block;
    }
    &:hover:after {
      font-size: 2.25em;
      opacity: 1;
    }

    &--h {
      flex: auto;
      height: 180px;
      min-width: 150px;
    }

    &.active::before {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 3px solid #00a5ff;
    }
    &__content {
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background: rgba(0 0 0 / 20%);
    }
  }

  &-img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}
</style>
