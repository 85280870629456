<template>
  <div>
    <v-row>
      <v-col cols="12">
        <p class="style-title" style="margin-bottom: 7px">Logotype</p>
        <cp-btn-logo-type
          @open-modal="open"
          @remove="removeItem"
          :image="message.logoPreview"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" style="height: 103px" class="pt-1 paddings-style">
        <span class="style-title">Title</span>
        <v-textarea
          class="design-message style-title"
          solo
          rows="2"
          name="input-7-4"
          placeholder="Your Title"
          v-model="message.title"
          @keyup="setData()"
          @blur="updateRedoUndo()"
          :height="50"
          type="text"
          flat
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" style="height: 143px" class="paddings-style">
        <span class="style-title">Description</span>
        <v-textarea
          class="design-message style-title"
          solo
          rows="3"
          name="input-7-4"
          placeholder="Let people know what the survey is for."
          v-model="message.description"
          @keyup="setData()"
          @blur="updateRedoUndo()"
          :height="90"
          flat
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="paddings-style message-confirmation-box">
        <div class="message-confirmation-box__block" @click="openConfirmMessageBox" v-if="!isHiddenButton"></div>
        <div class="message-confirmation-box__title">
          <p>
            <span
              class="style-title"          
            >
              Confirmation message
            </span>
          </p>
        </div>
        <v-textarea
          class="design-message style-title"
          solo
          rows="3"
          name="input-7-4"
          placeholder="This message will show when people complete your survey."
          v-model="message.confirmation"
          @keyup="setData()"
          @blur="updateRedoUndo()"
          :height="90"
          flat
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="paddings-style" style="height: 84px">
        <span class="style-title">Button</span>
        <combobox-icon
          :items="buttons"
          :height="35"
          @selected-item="setButton"
          :value="message.button.name"
          :showIcon="false"
        />
      </v-col>
    </v-row>
    <v-row v-if="showUrlText && message.button.name=='Visit our Page'">
      <v-col cols="12" class="paddings-style" style="height: 105px">
        <span class="style-title">Insert your page URL</span>
        <cp-input-text
          v-model="urlVisit"
        />
				<p 
					v-if="isValidate" 
					class="validation-qr"
				>
					{{ textValidation }}
				</p>
      </v-col>
    </v-row>
    <modal-upload
      v-if="uploadModal"
      @cerrar="uploadModal = !uploadModal"
      @chosen-item="chosenImage"
      :items="items"
      :selected-logo="logoImageType"
    />
  </div>
</template>

<script>
import ComboboxIcon from "@/components/comboboxes/ComboboxIcon.vue";
import CpBtnLogoType from "@/components/buttons/CpBtnLogoType.vue";
import CpInputText from "@/components/inputs/CpInputText";
import { validationUrl } from "@design/styleguide";
import ModalUpload from "../../../../../views/03-Uploads/ModalUpload.vue";

export default {
  name: "SideBarMessage",
  components: {
    ComboboxIcon,
    CpBtnLogoType,
    CpInputText,
    ModalUpload,
  },
  props: {
    message: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      buttons: [
        {
          name: "Send",
        },
        {
          name: "Visit our Page",
        },
      ],
      uploadModal: false,
      logoImageType: false,
      showUrlText: false,
      isConfirmation: false,
      items: [
        /* { tab: 'ISC Post' }, */
        { tab: "Upload Image/Video" },
        { tab: "Stock Images" },
      ],
			isValidate: false,
			textValidation: '',
			urlVisit: ''
    };
  },
  watch: {
    uploadModal(val) {
      if (!val) this.logoImageType = false;
    },
    urlVisit(url) {
			const data = validationUrl(url);
			if(!data.resp){ 
				this.isValidate = true; 
				this.textValidation = data.text;
				return 
			}
			this.textValidation = data.text;
			this.isValidate = false;

      this.$store.dispatch("addons/create/SET_MESSAGE_BUTTON_URL", url);
    },
    "message.confirmation"(message) {
      this.$emit("message-confirmation", message);
    },
    isConfirmation(val) {

      this.$emit("is-confirmation", val);
    },
  },
  validations() {
    return {
      urlVisit: {
        url,
      },
    };
  },
  mounted() {
    if (this.message.button.name === "Visit our Page") {
      this.showUrlText = true;
      this.urlVisit = this.message.button.url;
    }
  },
  computed: {
    isHiddenButton(){
      return this.$store.getters["addons/saved/hiddenSaveButton"];
    }
  },
  methods: {
    openConfirmMessageBox(){
      // this.isConfirmation = this.isHiddenButton;
      // this.$emit("is-confirmation", !this.isHiddenButton);
      this.$store.dispatch("addons/saved/IS_HIDDEN_BUTTON", !this.isHiddenButton);
    },
    open() {
      this.uploadModal = true;
      this.$store.dispatch("modals/open", "uploads");
      this.logoImageType = true;
    },
    setData() {
      this.$store.dispatch("addons/create/EVERY_MESSAGE", this.message);
    },
    updateRedoUndo(){
      this.$store.dispatch('addons/changes/SAVE_CHANGES');
    },
    setButton(button) {
      if (button.name === "Visit our Page") {
        this.showUrlText = true;
        this.urlVisit = "";
      } else {
        this.showUrlText = false;
        // insert end point default to send to leads table
        const urlLeads = "";
        this.$store.dispatch("addons/create/SET_MESSAGE_BUTTON_URL", urlLeads);
      }
      this.$store.dispatch("addons/create/SELECTED_MESSAGE_BUTTON", button);
      this.$store.dispatch('addons/changes/SAVE_CHANGES');
    },
    chosenImage(image) {
      this.$store.dispatch("addons/create/SELECTED_IMAGE_LOGO", image);
    },
    removeItem() {
      this.$store.dispatch("addons/create/REMOVE_IMAGE_LOGO");
    },
  },
};
</script>

<style lang="scss" src="./SideBarMessage.scss" scoped></style>
