<template>
  <div>
    <v-row class="pb-5">
      <v-col cols="12" style="margin-bottom: 3px">
        <p class="common-text margin-text">Background color</p>
        <div class="bt-design-color">
          <v-btn
            id="backgroundColorPickerBtn"
            :height="26"
            :min-width="26"
            class="button-color-design"
            :class="{'white-color-option': design.backcolor == '#FFFFFF'}"
            :color="design.backcolor"
            @click="openPickeColor('backgroundColor')"
          />
          <span class="common-text">
            <!-- {{ design.backcolor }} -->
            <v-text-field
            class="inputColors"
            placeholder="Background"
            solo
            maxlength="7"
            v-model="design.backcolor"
          ></v-text-field>
          </span>
        </div>
        <isc-color-picker
          id="backgroundColorPicker"
          v-if="colorPickerActive1"
          class="picker-color"
          v-model="backgroundColor"
          :show-opacity="false"
        />
      </v-col>
      <v-col cols="12" style="margin-top: 3px; margin-bottom: 3px">
        <p class="common-text margin-text">Button color</p>
        <div class="bt-design-color">
          <v-btn
          id="buttonColorPickerBtn"
            :height="26"
            :min-width="26"
            class="button-color-design"
            :class="{'white-color-option': design.buttoncolor == '#FFFFFF' }"
            :color="design.buttoncolor"
            @click="openPickeColor('buttonColor')"
          />
          <span class="common-text">
             <v-text-field
              class="inputColors"
              placeholder="Button"
              solo
              maxlength="7"
              v-model="design.buttoncolor"
            ></v-text-field>
          </span>
        </div>
        <isc-color-picker
          id="buttonColorPicker"
          v-if="colorPickerActive2"
          class="picker-color"
          v-model="buttonColor"
          :show-opacity="false"
        />
      </v-col>
      <v-col cols="12" style="margin-top: 3px">
        <p class="common-text margin-text">Button text color</p>
        <div class="bt-design-color">
          <v-btn
            id="buttontextcolorPickerBtn"
            :height="26"
            :min-width="26"
            class="button-color-design"
            :class="{'white-color-option': design.buttontextcolor == '#FFFFFF'}"
            :color="design.buttontextcolor"
            @click="openPickeColor('buttonTextColor')"
          />
          <span class="common-text">
            <v-text-field
              class="inputColors"
              placeholder="Button Text"
              solo
              maxlength="7"
              v-model="design.buttontextcolor"
            ></v-text-field>
          </span>
        </div>
        <isc-color-picker
          id="buttontextcolorPicker"
          v-if="colorPickerActive3"
          class="picker-color"
          v-model="buttonTextColor"
          :show-opacity="false"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { IscColorPicker } from "@design/styleguide";
export default {
  components: { IscColorPicker },
  name: "SideBarDesign",
  data() {
    return {
      colorPickerActive1: false,
      colorPickerActive2: false,
      colorPickerActive3: false,
      backgroundColor: "",
      buttonColor: "",
      buttonTextColor: "",
      isOnceBackgroundColor: false,
      isOnceButtonColor: false,
      isOnceButtonTextColor: false,
      pickerColorSelected:'',
    };
  },
  computed: {
    design() {
      return this.$store.getters["addons/create/design"];
    },
  },
  watch: {
    backgroundColor(color) {
      color = this.cropLengthColor(color);
      (!this.isOnceBackgroundColor) ? this.isOnceBackgroundColor = true : this.setColor(color, "background");
    },
    buttonColor(color) {
      color = this.cropLengthColor(color);
      (!this.isOnceButtonColor) ? this.isOnceButtonColor = true : this.setColor(color, "buttonColor");
    },
    buttonTextColor(color) {
      color = this.cropLengthColor(color);
      (!this.isOnceButtonTextColor) ? this.isOnceButtonTextColor = true : this.setColor(color, "buttonTextColor");
    },
  },
  created() {
    let parent = this;
    window.addEventListener('click', function(e){
      parent.hidePickeColorBackground(e);
      parent.hidePickeColorButton(e);
      parent.hidePickeColorButtonText(e);
    })
  },
  mounted() {
    // setting first time design into general addon object
    this.setColor("", "");
  },
  methods: {
    cropLengthColor(color){
      (color.length > 7) ? color = color.slice(0,7) : color ;
      return color;
    },
    setColor(color, type) {
      this.$store.dispatch("addons/create/SET_DESIGN_COLOR", {
        color,
        type,
      });
      if(color && type){
        this.$store.dispatch('addons/changes/SAVE_CHANGES');
      }
    },
    hidePickeColorBackground(e){
      let elementBox = document.getElementById('backgroundColorPicker');
      let isTarget;
      (elementBox) ? isTarget = elementBox.contains(e.target) : null;
      if(!isTarget && e.target.id !== 'backgroundColorPickerBtn' && this.colorPickerActive1) this.colorPickerActive1 = false;
    },
    hidePickeColorButton(e){
      let elementBox = document.getElementById('buttonColorPicker');
      let isTarget;
      (elementBox) ? isTarget = elementBox.contains(e.target) : null;
      if (!isTarget && e.target.id !== 'buttonColorPickerBtn' && this.colorPickerActive2) this.colorPickerActive2 = false;
    },
    hidePickeColorButtonText(e){
      let elementBox = document.getElementById('buttontextcolorPicker');
      let isTarget;
      (elementBox) ? isTarget = elementBox.contains(e.target) : null;
      if (!isTarget && e.target.id !== 'buttontextcolorPickerBtn' && this.colorPickerActive3) this.colorPickerActive3 = false;
    },
    openPickeColor(type){
      
      switch (type) {
        case 'backgroundColor':
          this.colorPickerActive2 = false;
          this.colorPickerActive3 = false;    
          this.colorPickerActive1 = !this.colorPickerActive1;
          this.pickerColorSelected = this.pickerColorSelected;
          break;
        case 'buttonColor':
          this.colorPickerActive1 = false;
          this.colorPickerActive3 = false;
          this.colorPickerActive2 = !this.colorPickerActive2
          this.pickerColorSelected = 'buttonColor';
          break;
        case 'buttonTextColor':
          this.colorPickerActive1 = false;
          this.colorPickerActive2 = false;
          this.colorPickerActive3 = !this.colorPickerActive3
          this.pickerColorSelected = 'buttonTextColor';
          break;      
        default:
          this.colorPickerActive1 = false;
          this.colorPickerActive2 = false;
          this.colorPickerActive3 = false;
          this.pickerColorSelected = '';
          break;
      }
    },
  },
};
</script>

<style src="./SideBarDesign.scss" lang="scss" scoped></style>
