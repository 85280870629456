<template>
  <v-navigation-drawer
    app
    :floating="$vuetify.breakpoint.mdAndUp"
    color="transparent"
    class="navigation-addon"
    style="
      position: relative;
      height: auto;
      transform: none;
      visibility: visible;
    "
  >
    <div>
      <v-list-item style="margin-top: 15px">
        <h4
          style="
            color: black;
            font-weight: 700;
            font-size: 15px;
          "
          class="ml-4"
        >
          Edit tools:
        </h4>
      </v-list-item>

      <v-list-item-group v-model="selectedItem" color="primary">
        <v-list-item v-for="(item, i) in items" :key="i">
          <v-list-item-icon class="mx-4">
            <v-icon v-text="item.icon" ></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="item.text"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </div>
  </v-navigation-drawer>
</template>

<script>
export default {
	name: "EditImageSidebar",
  data: () => ({
    select: 1,
    selectedItem: 0,
    items: [
      { text: "Resize", name: 'resize', icon: "mdi-fullscreen" },
      { text: "Crop", name: 'crop', icon: "mdi-crop" },
      { text: "Flip", name: 'flip', icon: "mdi-arrow-expand" },
    ],
  }),
  methods: {
    showTools(tool, index) {
      this.select = index;
      if (index == 2) {
        if (this.cropFlag) {
          this.cropEdit = false;
          this.cropFlag = false;
        } else {
          this.cropEdit = true;
          this.cropFlag = true;
        }
        this.$store.commit("addons/create/SET_CROP", {
          cropFlag: this.cropFlag,
          cropEdit: this.cropEdit,
        });
      }
      if (tool == 1) {
        this.tool1 = true;
        this.tool3 = false;
      } else if (tool == 3) {
        this.tool1 = false;
        this.tool3 = true;
      }
    },
  },
  watch: {
    selectedItem() {
      const selected = this.items[this.selectedItem]

      this.$emit('optionSelected', selected)
    }
  }
};
</script>

<style lang="scss">
.navigation-addon {
  .v-list-item__title {
    font-size: 15px;
  }

}
</style>