<template>
  <div class="edit-image__selector-cropped" :style="imageStyles">
    
    <div class="edit-image__selector-resize crop" :style="imageCropped" :class="opacity ? 'background-opacity' : ''">
      <img :src="image.path" style="width:100%;height:100%;"/>
    </div>
  </div>
</template>

<script>
export default {
	name:"EditImageBackgroundCrop",
  props: {
    contrast: {
      type: Boolean,
      default: false
    },
    image: {
      type: Object,
      default: Object
    },
    opacity: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    imageStyles() {
      return {
        ...this.image.styles,
        filter: this.contrast ? 'contrast(0.5)' : null
      }
    },
    imageCropped() {
      const { width, height, x, y, cropped } = this.$store.getters["addons/create/addon"];
      
      return {
        width: `${cropped.width}px`, 
        height: `${cropped.height}px`,
        transform: `translate(${cropped.x}px, ${cropped.y}px) `
      }
    }
  }
}
</script>
<style lang="scss" scoped>
  .edit-image__selector-resize.background-opacity {
    position: relative;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0,0,0,.5);
    }
  }
  .edit-image__selector-cropped {
    // overflow: hidden;
  }
</style>