<template>
  <v-row @click="setSelect(questionItem.id, questionIndex)">
    <div class="wrap__question__module">
      <div class="wrap__question__module__drag">
        <v-row style="padding: 10px" justify="end"
          ><v-icon small color="#5D6B88" style="cursor: pointer"
            >mdi-cursor-move</v-icon
          >
        </v-row>
        <v-row style="padding: 10px" justify="end">
          <v-icon
            small
            color="#5D6B88"
            @click="deleteQuestion(questionItem.id)"
            style="cursor: pointer"
            >mdi-trash-can-outline
          </v-icon></v-row
        >
      </div>
      <div class="col question-container ignore-elements px-9">
        <v-row no-gutters>
          <v-col
            cols="12"
            style="padding: 0; justify-content: center; align-items: center"
          >
            <v-row class="pt-0">
              <header-question
                questionName="What are your social newtork"
              ></header-question
            ></v-row>
            <v-row class="input-container">
              <v-text-field
                placeholder="Your social media information goes here"
                solo
                hide-details
                class="input-question"
                v-model="questionItem.question"
                @keyup="setQuestionByItem(questionItem.id, questionItem.question)"
              ></v-text-field>
            </v-row>
            <v-row>
              <v-col
                v-if="questionItem.iFrames.length > 0"
                cols="12"
                class="pl-0"
              >
                <header-question questionName="Place your iFrame here" />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                v-for="question in questionItem.iFrames"
                :key="question.id"
                cols="12"
              >
                <v-row class="input-container">
                  <v-text-field
                    placeholder="iframsrc='https://facebook.com/plugin...'"
                    solo
                    hide-details
                    class="input-question"
                    v-model="question.link"
                    @keyup="
                      setIframeByItem(questionItem.id, question.id, question.link)
                    "
                  ></v-text-field>
                  <v-icon
                    @click="deleteIframe(questionItem.id, question.id)"
                    small
                    color="#96A0B8"
                    class="closeIco ml-2"
                    >mdi-close-circle
                  </v-icon>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    class="question-container__iframe"
                    v-html="question.link"
                  >
                  </v-col>
                </v-row>
              </v-col>
              <v-row>
                <v-col cols="12">
                  <div
                    class="question-container__btn"
                    @click="addIframe(questionItem.id, true)"
                  >
                    <v-icon small color="#5D6B88">mdi-plus-thick </v-icon>
                    Add media network
                  </div>
                </v-col>
              </v-row>
            </v-row>
          </v-col>
        </v-row>
      </div>
    </div>
  </v-row>
</template>

<script>
import HeaderQuestion from "./HeaderQuestion.vue";
export default {
  name: "AnswerSocialNetwork",
  components: {
    HeaderQuestion,
  },
  props: {
    element: {
      type: Object,
      default: () => {
        return {};
      },
    },
    questionItem: {
      type: Object,
      required: true,
    },
    questionIndex: {
      type: Number,
      required: true,
    },
    questionSelected: {
      type: Number,
      required: true,
    },
    numberQuestion: {
      type: Number,
      default: 0,
    },
    items: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  mounted(){
    if (this.questionItem.iFrames.length <= 0)  this.addIframe(this.questionItem.id);
  },
  methods: {
    setSelect(id, index) {
      this.$emit("set-select", id, index);
    },
    setQuestionByItem(index, text) {
      this.$emit("set-question-by-item", index, text);
    },
    setIframeByItem(id_question, iframeId, link) {
      this.$emit("set-iframe-by-item", id_question, iframeId, link);
    },
    deleteQuestion(id_question) {
      this.$emit("delete-item", id_question);
    },
    addIframe(id_question) {
      this.$emit("add-iframe", id_question);
    },
    deleteIframe(questionItemId, questionId) {
      this.$emit("delete-iframe", questionItemId, questionId);
    },
  },
};
</script>

<style lang="scss" scoped>
.question-container {
  border: 1px solid #c5d0e4;
  &__btn {
    cursor: pointer;
    margin-bottom: 19px;
    margin-top: 10px;
    font-weight: 900;
    font-size: 10px;
    line-height: 16px;
  }
  &__iframe {
    background-color: #f3f4f7;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 6px 0px;
    margin: 13px 0px;
    min-height: 38px;
  }
}
.input-container {
  .input-question {
    border: 1px solid #c5d0e4;
    border-radius: 3px;
    ::v-deep .v-input__control {
      min-height: 35px;
      max-height: 35px;
      font-size: 12px;
      line-height: 16px;
      border-radius: 3px;
      .v-input__slot {
        box-shadow: inset 0px 2px 5px rgba(197, 208, 228, 0.62) !important;
        // box-shadow: none;
        input {
          &::placeholder {
            color: #5d6b88;
          }
        }
      }
    }
  }
}
</style>
