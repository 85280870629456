<template>
  <div>
    <label class="common-text">
      {{ label }}
    </label>
    <v-text-field
      class="text-input-addon"
      :height="height"
      solo
      flat
      dense
      :hide-details="!canShowMessages"
      :success="success"
      :type="type"
      :placeholder="placeholder"
      :disabled="disabled"
      :readonly="readonly"
      :autofocus="autofocus"
      :error-messages="modelError"
      @input="verify"
      @blur="verify"
      :color="color"
      v-model="model"
      autocomplete="off"
      :prefix="prefix"
      :append-icon="icon"
    />
  </div>
</template>
<script>
export default {
  name: "CpInputText",
  props: {
    canShowMessages: {
      type: Boolean,
      required: false,
      default: false,
    },
    value: [String, Number],
    autocomplete: String,
    label: String,
    type: {
      default: () => {
        return "text";
      },
    },
    success: {
      type: Boolean,
      default: false,
    },
    show: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    readonly: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    autofocus: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    placeholder: String,
    icon: {
      default: () => {
        return "";
      },
    },
    color: {
      type: String,
      default: "#00A5FF",
    },
    prefix: String,
    validate: {
      type: Object,
      default: () => {
        return {
          required: false,
          email: false,
          numeric: false,
          alpha: false,
          alphaNum: false,
          maxLength: 0,
          minLength: 0,
          decimal: false,
          maxValue: 0,
          between: 0,
          password: false,
        };
      },
    },
    v: {
      type: Object,
      required: false,
    },
    height: {
      type: Number,
      default: () => {
        return 35;
      },
    },
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    modelError() {
      const errors = [];
      if (this.v) {
        if (!this.v.$dirty) return errors;
        if (this.validate.required) {
          !this.v.required && errors.push("Field required");
        }
        if (this.validate.between) {
          !this.v.between &&
            errors.push(`Number should be between ${this.validate.between}`);
        }
        if (this.validate.alpha) {
          !this.v.alpha && errors.push("This field only accepts letters");
        }
        if (this.validate.alphaNum) {
          !this.v.alphaNum &&
            errors.push("This field only accepts letters and numbers");
        }
        if (this.validate.email) {
          !this.v.email && errors.push("Must be a valid e-mail");
        }
        if (this.validate.maxLength) {
          !this.v.maxLength &&
            errors.push(
              `This field accepts upto ${this.validate.maxLength} characters`
            );
        }
        if (this.validate.minLength) {
          !this.v.minLength &&
            errors.push(
              `This field accepts at least ${this.validate.minLength} characters`
            );
        }
        if (this.validate.decimal) {
          !this.v.decimal && errors.push(`Should be a decimal number`);
        }
        if (this.validate.numeric) {
          !this.v.numeric && errors.push(`This field only accepts numbers`);
        }
        if (this.validate.maxValue) {
          !this.v.maxValue &&
            errors.push(
              `Maximum accepted value is : ${this.validate.maxValue}`
            );
        }
        if (this.validate.password) {
          !this.v.sameAsPassword && errors.push(`Passwords don't match`);
        }
        if (this.validate.url) {
          !this.v.url && errors.push("This field only accepts URL");
        }
      }
      return errors;
    },
  },
  methods: {
    verify() {
      if (this.v) {
        this.v.$touch();
      }
    },
  },
};
</script>
<style scoped lang="scss">
.text-input {
  &-addon {
    ::v-deep .v-input__slot {
      border: 1px solid #c5d0e4;
      box-shadow: inset 0px 2px 5px rgba(197, 208, 228, 0.62);
      .v-text-field__slot {
        font-family: Oxygen;
        font-size: 12px;
        line-height: 16px;
      }
    }
  }
}
</style>
