var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex justify-center justify-lg-end"},[(_vm.elementsaved && _vm.elementsaved.path)?_c('v-sheet',{staticClass:"button-container"},[_c('div',{staticClass:"post-container"},[_c('div',{style:({
						width: _vm.element.width + 'px', 
						height: _vm.element.height + 'px',
						transform: `translate(${_vm.element.x}px, ${_vm.element.y}px) rotate(${_vm.element.transform.rotate}deg) scale(${_vm.element.transform.scale.x}, ${_vm.element.transform.scale.y})`,
						overflow: 'hidden'
					})},[_c('div',{style:({
						width: _vm.element.cropped.width + 'px', 
						height: _vm.element.cropped.height + 'px',
						transform: `translate(${_vm.element.cropped.x}px, ${_vm.element.cropped.y}px) rotate(${_vm.element.transform.rotate}deg)`
					})},[_c('img',{staticClass:"post-container__background",attrs:{"src":_vm.elementsaved.path}})])]),_c('div',{staticClass:"post-container__actions"},[_c('v-btn',{staticClass:"post-container__actions-edit",attrs:{"tile":"","small":"","width":"38","height":"34","min-width":"34"},on:{"click":_vm.openEditImageDialog}},[_c('v-icon',{attrs:{"size":"16"}},[_vm._v("fas fa-edit")])],1),_c('v-btn',{staticClass:"post-container__actions-close",attrs:{"icon":"","color":"white"},on:{"click":_vm.resetBackground}},[_c('v-icon',[_vm._v("fas fa-times")])],1)],1)]),_c('EditImageDialog')],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }