<template>
  <v-row @click="setSelect(questionItem.id, questionIndex)">
    <div class="wrap__question__module">

      <div class="wrap__question__module__drag">
        <v-row style="padding: 10px" justify="end"
          ><v-icon small color="#5D6B88" style="cursor: pointer"
            >mdi-cursor-move</v-icon
          >
        </v-row>
        <v-row justify="end" style="padding: 10px">
          <v-icon
            @click="deleteQuestion(questionItem.id)"
            small
            color="#5D6B88"
            style="cursor: pointer"
          >
            mdi-trash-can-outline
          </v-icon></v-row
        >
      </div>
      <div class="col box-question-container ignore-elements px-9">
        <v-row no-gutters>
          <!--
            :class="
              questionSelected == questionItem.id
                ? 'no-select-question'
                : 'select-question'
            "
          -->
          <v-col cols="12" style="" class="">
            <v-row class="pt-0">
              <header-question
                questionName="What would you like to ask?"
              ></header-question
            ></v-row>
            <v-row class="input-container">
              <v-text-field
                placeholder="Your question text goes here."
                solo
                hide-details
                class="input-question"
                v-model="questionItem.question"
                @keyup="setQuestionByItem(questionItem.id, questionItem.question)"
              ></v-text-field>
            </v-row>
            <v-row class="answers-container">
              <v-col
                v-for="question in questionItem.answers"
                :key="question.id"
                cols="12"
                class="pl-0 box_checkbox_item"
                style=""
              >
                <v-row no-gutters justify="center" align-content="center">
                  <v-col col="12">
                    <div class="box_checkbox_item__wrap d-flex">
                      <div class="checkbox-answer-container">
                        <v-checkbox
                          class="option-anwser-check"
                          style="margin-top: 0"
                          v-model="question.state"
                          disabled
                        ></v-checkbox>
                      </div>
                      <div class="input-answer-container">
                        <div v-if="question.other" class="pl-2 d-flex">
                          <span class="fontOther">Other: </span>
                        </div>
                        <div>
                          <v-text-field
                            v-if="!question.other"
                            class="align-self-center input-answer"
                            v-model="question.text"
                            style="width: 100%"
                            :placeholder="`Choice ${question.id}`"
                            solo
                            hide-details
                          ></v-text-field>
                          <v-text-field
                            v-if="question.other"
                            class="align-self-center input-answer"
                            v-model="questionItem.other_answer"
                            style="width: 100%"
                            :placeholder="`Other choice`"
                            solo
                            hide-details
                          ></v-text-field>
                        </div>
                      </div>
                      <div class="icon-close-answer-container">
                        <v-icon
                          @click="deleteAnswer(question.id, questionItem.id)"
                          small
                          color="#96A0B8"
                          >mdi-close-circle
                        </v-icon>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row class="mt-1">
              <v-col cols="12" class="">
                <button class="add-btn" @click="addAnswer(questionItem.id, true)">
                  <v-icon small color="#2C3852">mdi-plus-thick </v-icon>
                  <span> Add choice </span>
                </button>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
    </div>
  </v-row>
</template>

<script>
import HeaderQuestion from "./HeaderQuestion.vue";
export default {
  name: "AnswerCheckbox",
  components: {
    HeaderQuestion,
  },
  props: {
    element: {
      type: Object,
      default: () => {
        return {};
      },
    },

    questionItem: {
      type: Object,
      required: true,
    },
    questionIndex: {
      type: Number,
      required: true,
    },
    questionSelected: {
      type: Number,
      required: true,
    },
    numberQuestion: {
      type: Number,
      default: 0,
    },
    items: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  mounted() {
    this.setDefaultItems(3);
  },
  methods: {
    setSelect(id, index) {
      this.$emit("set-select", id, index);
    },
    deleteAnswer(id_answer, id_question) {
      this.$emit("delete-answer", id_answer, id_question);
    },
    addAnswer(id_question, isChekedType) {
      this.$emit("add-answer", id_question, isChekedType);
    },
    setQuestionByItem(index, text) {
      this.$emit("set-question-by-item", index, text);
    },
    deleteQuestion(id_question) {
      this.$emit("delete-item", id_question);
    },
    setDefaultItems(val) {
      if (this.questionItem.answers.length === 0) {
        for (let index = 0; index < val; index++) {
          this.addAnswer(this.questionItem.id, true);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.fontOther{
  color: #2c3852;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 16px;
}
.answers-container {
  // min-height: 140px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.box-question-container {
  border: 1px solid #c5d0e4;
  .option-anwser-check {
    padding-top: 0 !important;
    height: 35px;
    width: 24px;
    position: relative;
    z-index: 1;
    ::v-deep .v-input__control {
      height: inherit;
      .v-input__slot {
        height: inherit;
        margin-bottom: 0px !important;
        .v-input--selection-controls__input {
          &:hover {
            .v-input--selection-controls__ripple {
              &::before {
                background: none !important;
              }
            }
          }
        }
      }
    }
  }
  .box_checkbox_item {
    background-color: #f3f4f7;
    margin-top: 5px;
    padding: 0;
    padding-right: 10px !important;
    padding-left: 10px !important;
    height: 35px;
    max-height: 35px;
    min-height: 35px;
    border-radius: 3px;
    &__wrap {
      height: inherit;
    }
    .icon-close-answer-container {
      height: inherit;
      display: flex;
      align-items: center;
    }
    .checkbox-answer-container {
      ::v-deep .v-input__control {
        .v-input__slot {
          .v-icon {
            color: #c5d0e4 !important;
          }
        }
      }
    }
    &:first-child {
      margin-top: 13px;
    }
  }
}

.input-container {
  .input-question {
    border: 1px solid #c5d0e4;
    border-radius: 3px;
    ::v-deep .v-input__control {
      min-height: 35px;
      max-height: 35px;
      font-size: 12px;
      line-height: 16px;
      border-radius: 3px;
      .v-input__slot {
        box-shadow: inset 0px 2px 5px rgba(197, 208, 228, 0.62) !important;
        // box-shadow: none;
        input {
          &::placeholder {
            color: #5d6b88;
          }
        }
      }
    }
  }
}
.input-answer-container {
  height: 35px;
  width: 100%;
  display: flex;
  align-items: center;
  .input-answer {
    box-shadow: inset 0px 2px 5px rgba(197, 208, 228, 0.62);
    border-radius: 3px;
    ::v-deep .v-input__control {
      min-height: 25px;
      max-height: 25px;
      font-size: 12px;
      line-height: 16px;
      color: #5d6b88;
      .v-input__slot {
        padding-top: 0;
        background-color: #f3f4f7;
        box-shadow: none;
        input {
          color: #5d6b88 !important;
          &::placeholder {
            color: #A2ACC4 !important;
          }
        }
      }
    }
  }
}
.add-btn {
  border: none;
  background-color: #fff;
  span {
    color: #2c3852;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    position: relative;
    top: 1px;
  }
}
</style>
