<template>
  <v-btn
    :class="css"
    :block="block"
    :disabled="disabled"
    :style="[disabled && { backgroundColor: '#00A5FF' }]"
    color="#00A5FF"
    @click="callback($event)"
  >
    <v-progress-circular
      v-if="loading"
      indeterminate
      color="white"
      :size="25"
    ></v-progress-circular>
    <div v-else>{{ name }}</div>
  </v-btn>
</template>

<script>
export default {
  name: "CpBtnCommon",
  props: {
    name: String,
    css: {
      type: String,
      default: "addon-button",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    block: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    callback(e) {
      this.$emit("click", e);
    },
  },
};
</script>

<style lang="scss" scoped>
.addon-button {
  color: #ffffff !important;
  box-shadow: 0px 2px 4px rgba(44, 56, 82, 0.1);
  border-radius: 3px;
  font-weight: 700;
  padding: 9px 20px !important;
  text-transform: none;
  font-style: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: .1em;
}
</style>
