<template>
  <div class="edit-image__canvas-selector">
    <div class="edit-image__resize-tools" :style="image.styles">
      <div
        class="edit-image__resize-tl"
        @mousedown="resize($event, 'top-left')"
      ></div>
      <div
        class="edit-image__resize-tr"
        @mousedown="resize($event, 'top-right')"
      ></div>
      <div
        class="edit-image__resize-bl"
        @mousedown="resize($event, 'bottom-left')"
      ></div>
      <div
        class="edit-image__resize-br"
        @mousedown="resize($event, 'bottom-right')"
      ></div>
      <div
        class="edit-image__resize-box"
        @mousedown="movePosition($event)"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
	name:"EditImageSelectorResize",
  props: {
    imageCropped: {
      type: Object,
      default: Object
    },
  },
  data() {
    return {
      clicked: false,
      prevMouseMovement: {
        x: 0,
        y: 0
      },
      size: {
        width: 0,
        height: 0,
        cropped: {
          width: 0,
          height: 0
        }
      },
      position: {
        x: 0,
        y: 0
      },
      selectedCorner: null,
      sizeAvailable: {
        width: 0,
        height: 0
      },
    };
  },
  computed: {
    image() {
      const {
        id,
        thumbnails,
        width,
        height,
        x,
        y,
        transform: { rotate },
        cropped,
        locked
      } = this.$store.getters["addons/create/addon"];
      const currentImage = thumbnails.find(
        (item) => item.quality === "SCREEN_4"
      );

      const translate = `translate(${x}px,${y}px)`;
      const rotateDeg = `rotate(${rotate}deg)`;

      const transform = [translate, rotateDeg];
      return {
        id,
        width,
        height,
        x,
        y,
        cropped,
        locked,
        styles: {
          width: width + "px",
          height: height + "px",
          transform: transform.join(" "),
        },
        path: currentImage.location,
      };
    },
  },
  created() {
    this.size = {
      width: this.image.width,
      height: this.image.height,
      cropped: this.image.cropped
    }

    this.position.x = this.image.x;
    this.position.y = this.image.y;
  },
  methods: {
    resize(event, corner) {
      this.clicked = true;
      this.selectedCorner = corner;
      this.prevMouseMovement.x = event.clientX;
      this.prevMouseMovement.y = event.clientY;

      window.addEventListener("mousemove", this.onMouseMove);
      window.addEventListener("mouseup", this.onMouseUp);
    },
    onMouseMove(event) {
      if (this.clicked && event.buttons == 1) {
        let position = {
          x: this.position.x,
          y: this.position.y,
        };

        let width = this.image.width;
        let height = this.image.height;
        let widthCropped = this.image.cropped.width;
        let heightCropped = this.image.cropped.height;
        let xCropped = this.image.cropped.x;
        let yCropped = this.image.cropped.y;

        const clientX = (width >= 50 || height >= 50)  ? event.clientX : 0;
        const clientY = (width >= 50 || height >= 50)  ? event.clientY : 0;

        const movedMouseX = this.prevMouseMovement.x - clientX;
        const movedMouseY = this.prevMouseMovement.y - clientY;

        const sizeCanvas = {
          width: 955,
          height: 415
        }

        const remainingSize = {
          width: sizeCanvas.width - this.image.width, 
          height: sizeCanvas.height - this.image.height
        }

        const positionAvailable = {
          x: remainingSize.width,
          y: remainingSize.height,
        }


        switch (this.selectedCorner) {
          case "top-left":
            width = this.image.width + movedMouseX;
            height = this.image.height + movedMouseY;
            widthCropped = this.image.cropped.width + movedMouseX;
            heightCropped = this.image.cropped.height + movedMouseY;

            if((width <= 50 || height <= 50)) return;

            if(this.image.locked) {
              if(movedMouseX !== 0) {
                position.y -= (movedMouseX * this.image.height) / this.image.width
                position.x -= movedMouseX;
              } else {
                position.x -= (movedMouseY * this.image.width) / this.image.height
                position.y -= movedMouseY;
              }
            } else {
              position.x -= movedMouseX;
              position.y -= movedMouseY;
            }


            break;
          case "top-right":
            width = this.image.width - movedMouseX;
            height = this.image.height + movedMouseY;

            widthCropped = this.image.cropped.width - movedMouseX;
            heightCropped = this.image.cropped.height + movedMouseY;
            
            if((width <= 50 || height <= 50)) return;
            
            
            if(this.image.locked) {
              if(movedMouseX !== 0) position.y += (movedMouseX * this.image.height) / this.image.width
              else position.y -= movedMouseY;
            } else {
              position.y -= movedMouseY
            }

            break;
          case "bottom-left":
            width = this.image.width + movedMouseX;
            height = this.image.height - movedMouseY;

            widthCropped = this.image.cropped.width + movedMouseX;
            heightCropped = this.image.cropped.height - movedMouseY;

            if((width <= 50 || height <= 50)) return;

            if(this.image.locked) {
              if(movedMouseX !== 0) position.x -= movedMouseX;
              else position.x += (movedMouseY * this.image.width) / this.image.height
            } else {
              position.x -= movedMouseX
            }
            break;
          case "bottom-right":
            width = this.image.width - movedMouseX;
            height = this.image.height - movedMouseY;
            
            widthCropped = this.image.cropped.width - movedMouseX
            heightCropped = this.image.cropped.height - movedMouseY
            break;
          default: 
            // if(position.x - movedMouseX + 230 <= positionAvailable.x && position.x - movedMouseX + 230 >= 0) 
            position.x -= movedMouseX;
            // if(position.y - movedMouseY + 80 <= positionAvailable.y && position.y - movedMouseY + 80 >= 0) 
            position.y -= movedMouseY;
        }

        this.prevMouseMovement.x = event.clientX;
        this.prevMouseMovement.y = event.clientY;
        
        this.position.x = position.x;
        this.position.y = position.y;

        // this.sizeAvailable.width = sizeCanvas.width - this.position.x;
        // this.sizeAvailable.height = 426 - this.position.y;

        // SI LA CADENA ESTA ACTIVADA MANTIENE SU FORMATO
        if(this.image.locked) {
          if(movedMouseX !== 0) {
            heightCropped = (widthCropped * this.image.cropped.height) / this.image.cropped.width;
            height = (width * this.image.height) / this.image.width;
          } else {
            widthCropped = (heightCropped * this.image.cropped.width) / this.image.cropped.height;
            width = (height * this.image.width) / this.image.height;
          }
        }

        
        if (width <= 50 || height <= 50 ) return;

        if(this.size.cropped.height != height) {
          heightCropped = (height * this.image.cropped.height) / this.image.height;
        }

        if(this.size.cropped.width != width) {
          widthCropped = (width * this.image.cropped.width) / this.image.width;
        }

        if(this.size.cropped.x != 0) {
          xCropped = (width * this.image.cropped.x) / this.image.width;
        }

        if(this.size.cropped.y != 0) {
          yCropped = (height * this.image.cropped.y) / this.image.height;
        }
          
        // if(position.x + 230 <= positionAvailable.x && position.x + 230 >= 0) {
          this.size.width = width;
          this.size.cropped.width = widthCropped;
          this.size.cropped.x = xCropped;
        // }
        // if(position.y + 80 <= positionAvailable.y && position.y + 80 >= 0) {
          this.size.height = height;
          this.size.cropped.height = heightCropped;
          this.size.cropped.y = yCropped;
        // }
        
        // if(this.size.cropped.x != 0) this.size.cropped.y = (this.size.cropped.x * this.image.cropped.y) / this.image.cropped.x
        // if(this.size.cropped.y != 0) this.size.cropped.x = (this.size.cropped.y * this.image.cropped.x) / this.image.cropped.y

        this.$store.dispatch("addons/create/CHANGE_POSITION_IN_IMAGE", {
          x: this.position.x,
          y: this.position.y,
        });
        

        this.$store.dispatch("addons/create/CHANGE_SIZE_IN_IMAGE", {
          width: this.size.width,
          height: this.size.height,
        });

        if (widthCropped <= 50 || heightCropped <= 50 ) return;

        this.$store.dispatch('addons/create/CHANGE_SIZE_IN_CROP', {
          width: this.size.cropped.width,
          height: this.size.cropped.height,
        });
        // console.log('tamaños para cortar', this.size.cropped)
        // PARA X Y DEL CROP -> CHANGE_POSITION_IN_CROP
        this.$store.dispatch('addons/create/CHANGE_POSITION_IN_CROP', {
          x: this.size.cropped.x,
          y: this.size.cropped.y,
        });
        
      }
    },
    onMouseUp() {
      this.clicked = false;
      window.removeEventListener("mousemove", this.onMouseMove);
      window.removeEventListener("mouseup", this.onMouseUp);
      this.$store.dispatch('addons/editimage/SAVE_CHANGES');
    },
    movePosition(event) { 
      this.selectedCorner = null;
      this.clicked = true;
      this.prevMouseMovement.x = event.clientX;
      this.prevMouseMovement.y = event.clientY;
      window.addEventListener("mousemove", this.onMouseMove);
      window.addEventListener("mouseup", this.onMouseUp);
    },
  },
};
</script>
<style lang="scss" scoped>
.edit-image {
  &__canvas-selector {
    height: 256px;
    width: 491px;
    position: absolute;
  }

  &__resize {
    &-box {
      border: 1.5px solid #00a5ff;
      position: absolute;
      top: 0px;
      left: 0px;
      bottom: 0px;
      right: 0px;
      z-index: 10;
      cursor: move;
    }
    &-tl,
    &-tr,
    &-bl,
    &-br {
      height: 10px;
      width: 10px;
      background-color: #00a5ff;
      position: absolute;

      z-index: 20;
    }
    &-tl {
      left: -4px;
      top: -4px;
      cursor: nw-resize;
    }

    &-tr {
      right: -4px;
      top: -4px;
      cursor: ne-resize;
    }

    &-bl {
      bottom: -4px;
      left: -4px;
      cursor: ne-resize;
    }

    &-br {
      bottom: -4px;
      right: -4px;
      cursor: nw-resize;
    }
  }
}
</style>