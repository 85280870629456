<template>
    <v-btn
        title="Close"
        style="position: absolute; top: 12px; right: 15px"
        @click="$emit('click')"
        icon
        color=""
        class="btn-ico-close-modal"
      >
        <v-icon :color="color">$close</v-icon>
      </v-btn>
</template>
<script>
export default {
  name: 'CpBtnClose',
  props: {
    color: {
      type: String,
      default: '#696B76'
    }
  }
};
</script>
<style lang="scss">
.btn-ico-close-modal{
  height: 20px !important;
  width: 20px !important;
  &:focus,
  &:hover{
    &:before {
      opacity: 0 !important;
    }
  }
}
</style>
