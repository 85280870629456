<template>
	<v-slide-group
		v-model="model"
		@click:next="chargeData"
		:show-arrows="showArrows"
		 class="pa-1"
	>
		<v-slide-item 
			v-for="item in items" 
			:key="item.id"
		>
			<v-card 
				class="slide-card" 
				max-width="174.98" 
				height="235.26"
				@click="startClick(item)"
				
			>
				<div :class="{ 'active': item.id === activePostId }" >

					<div class="addon-image">
						<img  class="addon-image__pic" :src="item.finalImage" />
					</div>

					<v-card-title class="addon-card-title">
						{{item.name }}
					</v-card-title>

					<v-card-text class="text--primary addon-date">
						<div>{{ getStringDate(item.createdAt) }}</div>
					</v-card-text>
				</div>
			</v-card>
		</v-slide-item>
		<template v-if="isLoading">
			<v-slide-item v-for="i in 5">
				<v-card 
				:key="i"
				class="slide-card" 
				width="174.98" 
				height="230.26">
					<v-skeleton-loader type="image" height="175.98"></v-skeleton-loader>
					<v-skeleton-loader type="list-item"></v-skeleton-loader>
				
				</v-card>
			</v-slide-item>
		</template>
	</v-slide-group>
</template>

<script>
import { transformStringDate } from '@design/styleguide';
export default {
  name: "CpCardUpload",
  props: {
    addon: {
      type: Object,
      default: () => {
        return {
          url: 'https://cdn.vuetifyjs.com/images/cards/docks.jpg',
          name: 'POST 1',
          createdAt: '04-09-2021'
        }
      }
    },
	isLoading: Boolean,
	items: {
		type: Array,
		default: () =>{
			return []
		}
	},
	showArrows: {
		type:[ Boolean, String],
		default: () => {
			return false
		}
	},
	value: {
		type: [String, Number],
		default: null
	},
	activePostId: {
		type: Number,
		default: 0
	},
	
	desactivePost: Boolean
  },
	data: () => ({
    model: null,
		//activePostId: 0,
		oldId: 0
  }),
	watch: {
	},
	mounted(){
	},
	methods: {
		chargeData(){
			this.$emit('load-next', this.value);
		},
		getStringDate(date){
			return transformStringDate(date, 'MM-DD-YYYY');
		},
		startClick(item){
			this.$emit('chosen-post', item);
		},
		validationId(id){
			if(id !== this.activePost) this.activePost = null;
		}
	}
}
</script>

<style lang="scss" scoped>
	.slide {
		position: relative;
		min-height: 240px;
		::v-deep .v-slide-group__prev {
			min-width: 30px;
			flex-basis: 36px;
		}
		::v-deep .v-slide-group__next {
			min-width: 30px;
			flex-basis: 36px;
		}
		&-card {
			margin: 7px 12px 7px 3px;
			box-shadow: 0px 1px 5px 1px rgba(44, 56, 82, 0.1) !important;
			&.v-card--link:before{
				opacity: 0;
			}
		}
		.active::before{
			position: absolute;
			content: '';
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			border: 3px solid #00A5FF;
		}
	}

	.v-skeleton-loader__image {
		width: 174.98px;
		height: 174.98px;
	}
 .addon {
   &-image {
		display: flex;
		&__pic {
			width: 174.98px;
			height: 174.98px;
			border-radius: 6px 6px 0px 0px;
			object-fit: contain;
			background-color: #f3f4f7;
		}
   }
   &-card-title{
		font-weight: bold;
		font-size: 12px;
		line-height: 16px;
		padding: 15px 15px 0px
   }

   &-date {
    font-weight: normal;
    font-size: 10px;
    line-height: 16px;
		padding: 0px 15px 15px
   }
 }
</style>
