// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/cut-corner.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".edit-image__canvas-selector[data-v-83c7d528]{height:256px;width:491px;position:absolute}.edit-image__resize-tools-bg[data-v-83c7d528]{overflow:hidden;position:absolute}.edit-image__resize-box[data-v-83c7d528]{border:1.5px solid #00a5ff;position:absolute;top:0;left:0;bottom:0;right:0;z-index:10;cursor:move}.edit-image__resize-bl[data-v-83c7d528],.edit-image__resize-br[data-v-83c7d528],.edit-image__resize-tl[data-v-83c7d528],.edit-image__resize-tr[data-v-83c7d528]{height:17px;width:17px;pointer-events:auto;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");position:absolute;z-index:20}.edit-image__resize-tl[data-v-83c7d528]{left:0;top:0;cursor:nw-resize}.edit-image__resize-tr[data-v-83c7d528]{right:0;top:0;cursor:ne-resize;-webkit-transform:rotate(90deg);transform:rotate(90deg)}.edit-image__resize-bl[data-v-83c7d528]{bottom:0;left:0;cursor:ne-resize;-webkit-transform:rotate(270deg);transform:rotate(270deg)}.edit-image__resize-br[data-v-83c7d528]{bottom:0;right:0;cursor:nw-resize;-webkit-transform:rotate(180deg);transform:rotate(180deg)}.edit-image__horizontal[data-v-83c7d528],.edit-image__vertical[data-v-83c7d528]{position:absolute;top:0;left:0;width:100%;height:100%;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:space-evenly;-ms-flex-pack:space-evenly;justify-content:space-evenly}.edit-image__horizontal[data-v-83c7d528]{-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column}.edit-image__horizontal-line[data-v-83c7d528]{height:1px;width:100%;background-color:#00a5ff}.edit-image__vertical-line[data-v-83c7d528]{width:1px;height:100%;background-color:#00a5ff}", ""]);
// Exports
module.exports = exports;
