<template>
  <v-row>
    <v-col cols="5" class="main">
      <div class="main-box">
        <div v-if="!normalizedImage.location" @click="open" class="main-add">
          <v-icon class="main-add-icon" dense :size="18">
            mdi-plus-thick
          </v-icon>
          <p class="main-add-text common-text">Add Image</p>
        </div>
        <element v-else>
          <v-img :src="normalizedImage.location" aspect-ratio="1" :width="92" :height="66">
          </v-img>
        </element>
      </div>
    </v-col>
    <v-col cols="7" class="text">
      <v-row>
        <v-col cols="8" class="text-col1 logo-message d-flex flex-column">
          <span class="text-box pt-1">
            {{ normalizedImage.name }}
          </span>
          <span class="text-box pt-0">
            {{ normalizedImage.width }}px
            <b class="px-1">x</b> 
            {{ normalizedImage.height }}px
          </span>
        </v-col>
        <v-col cols="4" class="text-col2">
          <v-btn small v-if="normalizedImage.location" icon @click="remove">
            <v-icon dense :size="16"> mdi-trash-can-outline </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "CpBtnLogoType",
  props: {
    image: {
      type: Object,
      default: () => {
        return {};
      },
    },
    quality: {
      type: String,
      default: "SCREEN_3",
    },
  },
  computed: {
    normalizedImage() {
      return Object.assign(
        {
          name: "Recommended size",
          width: 300,
          height: 160,
          location: null,
        },
        this.image
      );
    },
  },
  methods: {
    open() {
      this.$emit("open-modal");
    },
    remove() {
      this.$emit("remove");
    },
  },
};
</script>

<style lang="scss" scoped>
.main {
  padding-bottom: 10px;
  &-box {
    width: 92px;
    height: 66px;
    border: 1px dashed #c5d0e4;
    box-sizing: border-box;
    cursor: pointer;
    overflow: hidden;
  }
  &-add {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    widows: 100%;
    height: inherit;
    &-text {
      margin-bottom: 0;
      margin-top: 4px;
      color: #5D6B88;
    }
    &-icon {
      color: #5D6B88;
      margin: 0;
    }
  }
}
.text {
  padding-left: 8px;
  padding-bottom: 10px;
  &-col1 {
    padding-left: 4px;
    padding-right: 0px;
  }
  &-col2 {
    padding: 6px 0px 0px 0px;
  }
  &-box {
    font-family: Oxygen;
    font-size: 10px;
    line-height: 16px;
    color: #5D6B88;
    padding-top: 7px;
  }
}
</style>
