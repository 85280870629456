<template>
  <v-row @click="setSelect(questionItem.id, questionIndex)">
    <div class="wrap__question__module">
      <div class="wrap__question__module__drag">
        <v-row style="padding: 10px" justify="end"
          ><v-icon small color="#5D6B88" style="cursor: pointer"
            >mdi-cursor-move</v-icon
          >
        </v-row>
        <v-row justify="end" style="padding: 10px">
          <v-icon
            @click="deleteQuestion(questionItem.id)"
            small
            color="#5D6B88"
            style="cursor: pointer"
          >
            mdi-trash-can-outline
          </v-icon></v-row
        >
      </div>
      <div class="col box-question-container ignore-elements px-9">
        <v-row no-gutters>
          <v-col cols="12" style="">
            <v-row class="pt-0">
              <header-question
                questionName="What would you like to ask?"
              ></header-question>
            </v-row>
            <v-row class="input-container">
              <v-text-field
                placeholder="Your question text goes here."
                solo
                hide-details
                class="input-question"
                v-model="questionItem.question"
                @keyup="setQuestionByItem(questionItem.id, questionItem.question)"
              ></v-text-field>
            </v-row>
            <v-row class="box-question-container__radio align-start">
              <v-radio-group
                readonly
                style="width: 100%"
                class="mt-0"
                v-model="radios"
              >
                <v-col
                  v-for="question in questionItem.answers"
                  :key="question.id"
                  cols="12"
                  class="radio_input"
                  style=""
                >
                  <v-row no-gutters justify="center" align-content="center">
                    <v-col cols="12">
                      <div class="box-question-container__radio__wrap d-flex">
                        <div class="radio-answer-container">
                          <v-radio v-model="question.state" style="margin-top: 0">
                          </v-radio>
                        </div>
                        <div class="input-answer-container">
                          <v-text-field
                            class="align-self-center input-answer"
                            v-model="question.text"
                            style="width: 100%"
                            :placeholder="`Choice ${question.id}`"
                            solo
                            hide-details
                          ></v-text-field>
                        </div>
                        <div
                          class="icon-close-answer-container d-flex aling-items-center"
                        >
                          <v-icon
                            @click="deleteAnswer(question.id, questionItem.id)"
                            small
                            color="#96A0B8"
                            >mdi-close-circle
                          </v-icon>
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
              </v-radio-group>
            </v-row>
            <v-row class="mt-1">
              <v-col cols="12" class="pt-0">
                <button class="add-btn" @click="addAnswer(questionItem.id, true)">
                  <v-icon small color="#2C3852">mdi-plus-thick </v-icon>
                  <span> Add choice </span>
                </button>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
    </div>
  </v-row>
</template>

<script>
import HeaderQuestion from "./HeaderQuestion.vue";
export default {
  name: "AnswerRadioButton",
  components: {
    HeaderQuestion,
  },
  data() {
    return {
      radios: "",
    };
  },
  props: {
    element: {
      type: Object,
      default: () => {
        return {};
      },
    },

    questionItem: {
      type: Object,
      required: true,
    },
    questionIndex: {
      type: Number,
      required: true,
    },
    questionSelected: {
      type: Number,
      required: true,
    },
    numberQuestion: {
      type: Number,
      default: 0,
    },
    items: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  mounted() {
    this.setDefaultItems(2);
  },
  methods: {
    deleteQuestion(id_question) {
      this.$emit("delete-item", id_question);
    },
    setSelect(id, index) {
      this.$emit("set-select", id, index);
    },
    setQuestionByItem(index, text) {
      this.$emit("set-question-by-item", index, text);
    },
    addAnswer(id_question, isCheckedType) {
      this.$emit("add-answer", id_question, isCheckedType);
    },
    deleteAnswer(id_answer, id_question) {
      this.$emit("delete-answer", id_answer, id_question);
    },
    setDefaultItems(val) {
      if (this.questionItem.answers.length === 0) {
        for (let index = 0; index < val; index++) {
          this.addAnswer(this.questionItem.id, true);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.box-question-container {
  border: 1px solid #c5d0e4;
  &__radio {
    // min-height: 140px;
    padding-top: 10px;
    ::v-deep .v-input--selection-controls__ripple {
      display: none;
    }
    ::v-deep .v-input__control {
      .v-input__slot {
        margin-bottom: 0;
      }
    }
    .radio_input {
      background-color: #f3f4f7;
      margin-top: 5px;
      padding: 0;
      padding-right: 10px;
      padding-left: 10px;
      height: 35px;
      border-radius: 3px;
      justify-content: space-between;
      &:first-child {
        margin-top: 0;
      }
    }
    &__wrap {
      height: 35px;
      div {
        height: inherit;
      }
      .input-answer {
        box-shadow: none !important;
      }
    }
  }
}
.input-container {
  .input-question {
    border: 1px solid #c5d0e4;
    border-radius: 3px;
    ::v-deep .v-input__control {
      min-height: 35px;
      max-height: 35px;
      font-size: 12px;
      line-height: 16px;
      border-radius: 3px;
      .v-input__slot {
        box-shadow: inset 0px 2px 5px rgba(197, 208, 228, 0.62) !important;
        // box-shadow: none;
        input {
          &::placeholder {
            color: #5d6b88;
          }
        }
      }
    }
  }
}
.input-answer-container {
  max-height: 35px;
  width: 100%;
  .input-answer {
    box-shadow: inset 0px 2px 5px rgba(197, 208, 228, 0.62);
    border-radius: 3px;
    ::v-deep .v-input__control {
      min-height: 35px;
      max-height: 35px;
      font-size: 12px;
      line-height: 16px;
      color: #5d6b88;
      .v-input__slot {
        padding-top: 0;
        padding-left: 0;
        background-color: #f3f4f7;
        box-shadow: none;
        height: 35px;
        input {
          color: #5d6b88 !important;
          &::placeholder {
            color: #A2ACC4 !important;
          }
        }
      }
    }
  }
}
.add-btn {
  border: none;
  background-color: #fff;
  span {
    color: #2c3852;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    position: relative;
    top: 1px;
  }
}
 .radio-answer-container {
      ::v-deep .v-input--selection-controls__input {
        .v-icon {
          color: #c5d0e4 !important;
        }
      }
    }
</style>
