<template>
  <div class="main-lienzo">
    <div
      style="width: 100%; height: 100%; overflow: hidden"
      class="d-flex align-center justify-center"
			
    >
      <div class="edit-image__canvas">
        <EditImageBackground :image="image" :contrast="true" />
      </div>
      <div class="edit-image__canvas-mirror">
        <EditImageBackground :image="image" />
      </div>
    </div>
    <div class="edit-image__content-selector" v-if="selectedItemSidebar === 'resize' || selectedItemSidebar === 'flip'">
      <EditImageSelectorResize :imageCropped="imageCropped"/>
    </div>

    <div class="edit-image__content-selector" v-if="selectedItemSidebar === 'crop'">
      <div class="edit-image__canvas">
        <EditImageBackgroundCrop :image="image" :opacity="true" />
      </div>
      <EditImageSelectorCrop/>
    </div>
  </div>
</template>

<script>
import EditImageSelectorCrop from "./EditImageSelectorCrop";
import EditImageBackground from "./EditImageBackground";
import EditImageSelectorResize from "./EditImageSelectorResize";
import EditImageBackgroundCrop from './EditImageBackgroundCrop';
export default {
	name:"EditImageContent", 
  components: {
		EditImageSelectorCrop,
    EditImageBackground,
    EditImageSelectorResize,
    EditImageBackgroundCrop
  },
  data() {
    return {
      select: 1,
      styleCustom: "",
      position: {
        x: 0,
        y: 0,
      },
    };
  },
  props: {
    selectedItemSidebar: { // 0 - Resize, 1 = Crop, 2 = Flip
      type: String,
      default: '',
    },
  },
  computed: {
    image() {
      const {
        id,
        thumbnails,
        width,
        height,
        x,
        y,
        transform: { scale, rotate },
        locked,
        path
      } = this.$store.getters["addons/create/addon"];
      const currentImage = thumbnails.find(
        (item) => item.quality === "SCREEN_4"
      );

      // console.log(scale, 'ooo')
      const flip = `scale(${scale.x}, ${scale.y})`;
      const translate = `translate(${x}px,${y}px)`;
      const rotateDeg = `rotate(${rotate}deg)`;

      const transform = [translate, rotateDeg, flip];
      return {
        id,
        styles: {
          width: width + "px",
          height: height + "px",
          transform: transform.join(" "),
          // transition: '.4s '
        },
        locked,
        path
        // path: currentImage.location,
      };
    },
    imageCropped() {
      // INFO DEL ELEMENTO CORTADO
      // width: ANCHO CORTADO
      // height: ALTO CORTADO
      // x: UBICACION X
      // Y: UBICACION Y

      // DATOS ORIGINALES
      // WIDTH: ORIGINAL
      // HEIGHT: ORIGINAL
      // X: TOMANDO COMO PUNTO DE ORIGEN EL CORTAOD
      // Y: TOMANDO COMO PUNTO DE ORIGEN EL CORTAOD

      const { width, height, x, y, cropped } = this.$store.getters["addons/create/addon"];

      return {
        width: `${width}px`, 
        height: `${height}px`,
        transform: `translate(${cropped.x}px, ${cropped.y}px)`
      }
    }
  },

  methods: {
    // activateEv(el1, el2) {
    //   console.log("elemnest ", el1, el2);
    // },
    // deactivateEv(el) {
    //   console.log(" element ", el);
    // },
    // changePosition(event, el) {
    //   console.log("event ", event);
    //   console.log("element", el);
    // },
    // changeSize(dimensiones) {
    //   //console.log("", dimensiones);
    //   let w = parseFloat(dimensiones.width);
    //   let h = parseFloat(dimensiones.height);
    //   this.$store.commit("addons/create/SET_WIDTH", w);
    //   this.$store.commit("addons/create/SET_HEIGHT", h);
    // },
  },
};
</script>

<style lang="scss" scoped>
.main-lienzo {
	position: relative; 
	width: 100%; 
	height: 100%;
}
.edit-image {
  &__canvas-mirror,
  &__canvas {
    height: 256px;
    width: 491px;
    border: 1px solid #fff;
    margin: -1px;
  }
  &__canvas-mirror {
    position: absolute;
    overflow: hidden;
  }
  &__canvas {
    position: relative;
    background-color: #fff;
  }

  &__content-selector {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>