<template>
  <v-row align="center">
    <v-col cols="10">
      <v-row
        v-if="selectedItemSidebar === 'resize' || selectedItemSidebar === 'crop'"
        align="center"
      >
        <v-col cols="auto" style="display: flex; align-items: center">
          <h5 class="mr-4">Dimensions:</h5>
          <h5 style="color: #5d6b88; padding-right: 10px">H:</h5>
          <v-text-field
            class="search-input"
            hide-details
            dense
            outlined
            placeholder="120px"
            type="number"
            style="width: 90px;"
            @input="updateDimension($event, 'height')"
            :value="image.height"
          />
        </v-col>
        <v-col cols="auto" style="display: flex; align-items: center">
          <h5 style="color: #5d6b88; padding-right: 10px">W:</h5>
          <v-text-field
            class="search-input"
            hide-details
            dense
            outlined
            placeholder="120px"
            type="number"
            style="width: 90px;"
            @input="updateDimension($event, 'width')"
            :value="image.width"
          />
        </v-col>
        <v-col
          cols="auto"
          style="max-width: 6%; display: flex; align-items: center"
          
        >
          <v-btn icon @click="lockImage" :color="image.locked === true ? 'primary' : ''">
                <v-icon dense class="string-icon">mdi-link </v-icon>
          </v-btn>
        </v-col>
        <v-col cols="auto" style="display: flex; align-items: center">
          <h5 style="color: #5d6b88; padding-right: 10px">Rotate:</h5>
          <v-btn icon @click="rotateImage('right')">
                <v-icon dense>mdi-phone-rotate-landscape </v-icon>
          </v-btn>
          <v-btn icon @click="rotateImage('left')" style="transform: scaleX(-1)">
                <v-icon dense>mdi-phone-rotate-landscape </v-icon>
          </v-btn>
        </v-col>
        <div>
        </div>
      </v-row>
      <v-row
        v-if="selectedItemSidebar === 'flip'"
         align="center"
        style="height: 64px;"
      >
        <v-col>
          <v-btn text @click="flipImage('horizontal')" :color="image.transform.scale.x === -1 ? 'primary': ''">
            Flip horizontal
          </v-btn>
          <v-btn text @click="flipImage('vertical')" :color="image.transform.scale.y === -1 ? 'primary': ''">
            Flip vertical
          </v-btn>
        </v-col>
      </v-row>
    </v-col>

    <v-col cols="2" class="d-flex align-center justify-end">
      <v-btn icon @click="undo" :disabled="canUndo">
        <v-icon class="back-icon" color="#5D6B88">$back-step</v-icon>
      </v-btn>
      <v-btn icon @click="redo" :disabled="canRedo">
        <v-icon class="next-icon" color="#5D6B88">$next-step</v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
	name:"EditImageTools",
  props: {
    selectedItemSidebar: {
      type: String,
      default: 'resize'
    }
  },
  data() {
    return {
      imageWidth: 0,
      imageHeight: 0,
      flipVertical: 1,
      flipHorizontal: 1,
    }
  },
  computed: {
    image() {
      return this.$store.getters["addons/create/addon"];
    },
    canRedo() {
      return this.$store.getters["global/redoundoAddonImage/canRedo"];
    },
    canUndo() {
      return this.$store.getters["global/redoundoAddonImage/canUndo"];
    },
  },
  methods: {
    flipImage(text) {
      
      switch (text) {
        case "horizontal":
          this.flipHorizontal = this.flipHorizontal === 1 ? -1 : 1
          break;
        case "vertical":
          this.flipVertical = this.flipVertical === 1 ? -1 : 1
          break;
      }

      // let currentFlip = `scale(${this.flipHorizontal}, ${this.flipVertical})`;
      this.$store.dispatch("addons/create/CHANGE_FLIP_IN_IMAGE", { x: this.flipHorizontal, y: this.flipVertical });
      this.$store.dispatch('addons/editimage/SAVE_CHANGES');
    },
    lockImage() {
      this.$store.dispatch('addons/create/CHANGE_STATUS_LOCK', !this.image.locked);
      this.$store.dispatch('addons/editimage/SAVE_CHANGES');
    },
    updateDimension(val, type) {
      
      if(val >= 50) {
        
        let width = this.image.width;
        let height = this.image.height;

        switch(type) {
          case 'width':
              width = parseInt(val)
              if(this.image.locked) height = (parseInt(val) * this.image.height) / this.image.width
            break;
            case 'height':
              height = parseInt(val)
              if(this.image.locked) width = (parseInt(val) * this.image.width) / this.image.height
            break;
        }
  
        let widthCropped = this.image.cropped.width;
        let heightCropped = this.image.cropped.height;
        let xCropped = this.image.cropped.x;
        let yCropped = this.image.cropped.y;

        if(this.image.cropped.height != height) {
          heightCropped = (height * this.image.cropped.height) / this.image.height;
        }

        if(this.image.cropped.width != width) {
          widthCropped = (width * this.image.cropped.width) / this.image.width;
        }

        if(this.image.cropped.x != 0) {
          xCropped = (width * this.image.cropped.x) / this.image.width;
        }

        if(this.image.cropped.y != 0) {
          yCropped = (height * this.image.cropped.y) / this.image.height;
        }

        this.$store.dispatch("addons/create/CHANGE_SIZE_IN_IMAGE", {
          width,
          height,
        });        

        this.$store.dispatch('addons/create/CHANGE_SIZE_IN_CROP', {
          width: widthCropped,
          height: heightCropped
        })

        this.$store.dispatch('addons/create/CHANGE_POSITION_IN_CROP', {
          x: xCropped,
          y: yCropped,
        });
      }

      this.$store.dispatch('addons/editimage/SAVE_CHANGES');
    },
    rotateImage(direction) {
      
      let degreeToRotate = 0;
      switch(direction) {
        case 'right':
          degreeToRotate = 90;
          break;
        case 'left':
          degreeToRotate = -90;
          break;
      }

      const totalDegree = this.image.transform.rotate + degreeToRotate;

      let currentDegree = 0;
  
       if(totalDegree >= 360) {
         currentDegree = totalDegree - 360
       } else if(totalDegree <= 0) {
         currentDegree = totalDegree + 360
       } else {
         currentDegree = totalDegree
       }
      this.$store.dispatch('addons/create/CHANGE_ROTATION', currentDegree);
      this.$store.dispatch('addons/editimage/SAVE_CHANGES');
    },
    redo() {
      this.$store.dispatch("global/redoundoAddonImage/REDO");
    },
    undo() {
      this.$store.dispatch("global/redoundoAddonImage/UNDO");
    },
  }
};
</script>

<style lang="scss" scoped>
  .back {
    &-icon {
      width: 12px !important;
      height: 16px !important;
    }
  }

  .string-icon {
    transform: rotate(90deg);
  }
  .next {
    &-icon {
      width: 12px !important;
      height: 16px !important;
    }
  }
</style>