<template>
  <div class="addon">
    <div v-if="isScreensizeLess" class="d-flex justify-center align-center" style="height: 100%; background-color: #00a5ff;">
      <screensize-modal style="margin-top: 6rem;"/>
    </div>
    <div class="addon__main" v-if="!isScreensizeLess">
      <aside class="aside__sidebar">
        <sidebar-content @id-path="pathId" />
      </aside>
      <div class="aside__content">
        <div class="aside__content__header">
          <header-content @search-text="dataText" />
        </div>
        <content-gallery :path-name="idPath" :search="searchText" />
      </div>
    </div>
  </div>
</template>

<script>
import SidebarContent from "@/components/content/sidebar/SidebarContent";
import HeaderContent from "../components/content/header/HeaderContent";
import ContentGallery from "@/components/content/ContentGallery";
import { ScreensizeModal } from "@design/styleguide";
export default {
  name: "Addon",
  components: {
    SidebarContent,
    HeaderContent,
    ContentGallery,
    ScreensizeModal,
  },
  data: () => ({
    idPath: "",
    searchText: null,
    isScreensizeLess: false,
  }),
  methods: {
    pathId(val) {
      if (val === "") return;
      this.idPath = val;
    },
    dataText(val) {
      this.searchText = val;
    },
    getDimensions() {
      this.width = document.documentElement.clientWidth;
      (this.width < 1024) ? this.isScreensizeLess = true : this.isScreensizeLess = false;
    },
  },
  created() {
    this.getDimensions();
    window.addEventListener("resize", this.getDimensions);    
  },
};
</script>
<style scoped lang="scss">
.addon {
  height: 100%;
  &__main {
    max-width: 100%;
    margin: 0 auto;
    position: relative;
    padding: 0px 0px 0px 0px;
    // height: 100%;
    display: flex;
    flex-direction: row;
  }
}
.aside {
  &__sidebar {
    position: relative;
    width: 202px;
    background: #273444;
    min-height: 600px;
  }
  &__content {
    //margin-left: 202px;
    width: calc(100% - 202px);
    //width: 100%;
    //clear: right;
    background: #F3F4F7;
    height: 100%;
    flex: 1;
    &__header {
      position: fixed;
      top: 60px;
      width: 100%;
      z-index: 10;
      width: calc(100% - 202px);
    }
  }
}
</style>
