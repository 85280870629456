<template>
	<div>
		<div 
			 v-for="item in items" 
			 :key="item.id"
			 class="latest-addons__item"
			 @click="startClick(item)"
			 
			>
			<div :class="{ 'latest-addons__active': item.id === activePostId }">
				<div class="latest-addons__lienzo">		
					<img
						class="latest-addons__image"
						:src="item.finalImage"
					/>
				</div>
				<v-sheet
					class="latest-addons__card-footer"
					:min-height="49"
				>
					<div class="latest-addons__name">{{ item.name }}</div>
					<div class="latest-addons__date"> {{ getStringDate(item.createdAt) }}</div>
				</v-sheet>
			</div>
		</div>
	</div>
</template>

<script>
import { transformStringDate } from "@design/styleguide"
export default {
	name: 'CpCardListPost',
	props: {
		items: {
			type: Array,
			default: () => {
				return []
			}
		},
		activePostId: {
			type: Number,
			default: 0
		},
	},
	data() {
		return {
			
		};
	},
	methods: {
		getStringDate(date){
			return transformStringDate(date, 'MM-DD-YYYY');
		},
		startClick(item){
			this.$emit('chosen-post', item);
		},
	},
};
</script>

<style lang="scss" scoped>
	.latest-addons{
		//padding-right: 5px;
		&__item{
			position: relative;
			width: 174.98px;
			height: 235.26px;
			float: left;
			//text-align: center;
			margin: 0px 15px 58px 0px;
			border-radius: 6px;
			background: #FFFFFF;
			box-shadow: 0px 2px 6px 2px rgba(44, 56, 82, 0.1);
			border-radius: 6px;
		}
		&__lienzo{
			max-height: 174.98px;
		}
		&__active::before{
			position: absolute;
			content: '';
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			border: 3px solid #00A5FF;
		}
		
		&__image{
			width: 174.98px;
			height: 174.98px;
			border-radius: 6px 6px 0px 0px;
			object-fit: contain;
			background-color: #f3f4f7;
		}
		
		&__title{
			font-family: Oxygen;
			font-size: 8px;
			font-weight: 700;
			line-height: 20px;
			color: black;
			text-align: center;
		}
		
		&__name{
			text-align: left;
			font-size: 15px;
			line-height: 16px;
			font-weight: bold;
			margin-bottom: 4px;
		}
		&__date {
			font-weight: normal;
			font-size: 10px;
			line-height: 16px;
			text-align: left;
		}
		
		&__card-footer {
			padding-top: 15.07px;
			padding-left: 15.07px;
		}
	}
	
</style>